export const Langs = [
  {
    lang: "en",
    img: "gb",
    name: "English",
  },
  {
    lang: "ms",
    img: "my",
    name: "Bahasa melayu",
  },
  {
    lang: "id",
    img: "id",
    name: "Bahasa Indonesia",
  },
  {
    lang: "th",
    img: "th",
    name: "ภาษาไทย",
  },
  {
    lang: "zh",
    img: "cn",
    name: "简体中文",
  },
  {
    lang: "vi",
    img: "vn",
    name: "Tiếng Việt",
  },
  {
    lang: "ko",
    img: "kr",
    name: "한국어",
  },
  {
    lang: "fil",
    img: "ph",
    name: "Filipino",
  },
  {
    lang: "lo",
    img: "la",
    name: "ພາສາລາວ",
  },
  {
    lang: "hi",
    img: "in",
    name: "हिन्दी",
  },
  {
    lang: "ja",
    img: "jp",
    name: "日本語",
  },
];
