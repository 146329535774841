import { getBonusCreditMe } from "../api/bonus";

const bonusCredit = async () => {
  const bonus = await getBonusCreditMe();

  if (bonus?.data?.data?.length === 0 || !bonus?.data?.data?.length) {
    return [
      {
        bonus_pct: 0,
        maximum: 0,
        priority: 1,
      },
      {
        bonus_pct: 0,
        maximum: 0,
        priority: 2,
      },
    ];
  } else if (bonus?.data?.data?.length === 1) {
    return [
      {
        bonus_pct: bonus.data.data[0].bonus_pct,
        maximum: bonus.data.data[0].maximum,
        priority: bonus.data.data[0].priority,
      },
      {
        bonus_pct: 0,
        maximum: 0,
        priority: 2,
      },
    ];
  }

  return bonus?.data?.data;
};

export default bonusCredit;
