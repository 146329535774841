export default {
  th: {
    bonus_update: "อัปเดต",
    bonus_update_warning: "โปรดรอสักครู่ก่อนจะอัปเดตอีกครั้ง",
    received: "ได้รับแล้ว",
    no_deposit_bonus: "	ไม่มีโบนัสเงินฝาก	",
    bonus_30_detail:
      "โบนัส 30 USD ทันทีจะได้รับเครดิตในบัญชีของคุณโดยอัตโนมัติเมื่อเปิดบัญชีต้อนรับ คุณสามารถแลกเปลี่ยนกับโบนัสนี้ได้ และผลกำไรใด ๆ ที่คุณได้รับจะถูกถอนออกตามเงื่อนไขเปลี่ยนเงินทุนฟรีของคุณเป็นผลกำไรจริง!	",
    starts: "	เริ่มต้น	",
    up_to: "	จนถึง	",
    commission_free: "	ค่าคอมมิชชั่นฟรี	",
    deposit_bonus: "	โบนัสเงินฝาก	",
    deposit_bonus_35:
      "	เพิ่มทุนการค้าของคุณด้วยโบนัสเงินฝาก {{value1}}% ฝากจำนวนเงินที่คุณเลือกและรับโบนัสเงินฝากสูงสุด {{value2}} USD ไปยังบัญชีของคุณ การค้าด้วยความมั่นใจและคว้าโอกาสในตลาดการเงิน	",
    deposit_bonus_25:
      "	เพิ่มทุนการค้าของคุณด้วยโบนัสเงินฝาก {{value1}}% ฝากจำนวนเงินที่คุณเลือกและรับโบนัสเงินฝากสูงสุด {{value2}} USD ไปยังบัญชีของคุณ อย่าพลาดโอกาสที่ยอดเยี่ยมนี้ในการเพิ่มทุนการค้าของคุณ	",
    bonus_condition: "	เงื่อนไขโบนัส	",
    bonus_condition_detail:
      "	คุณจะได้รับโบนัสจำนวนเงินที่เป็นสัดส่วนกับผลรวมที่ฝากโดยมีขีด จำกัด สูงสุดตามที่ระบุ	",
    bonus_notice_error: "คุณไม่ได้ผ่านเงื่อนไขทั้งหมดสำหรับการถอนโบนัส",
    withdraw_option: "	ตัวเลือกการถอน	",
    invite_more: "	เชิญเพิ่มเติมรับมากขึ้น	",
    invite: "	เชิญ	",
    invite_person: "	เชิญเพิ่มเติม/ บุคคล	",
    maximum_withdraw: "	การถอนสูงสุด	",
    invie_link: "	ลิงค์คำเชิญ	",
    reminder: "	เตือนความจำ	",
    remider_detail:
      "	ผู้ที่ได้รับเชิญจะต้องตรวจสอบตัวตนและบัญชีธนาคารของพวกเขาสำเร็จและรับโบนัสก่อน ดังนั้นจึงจะได้รับการพิจารณาว่าการเชิญชวนเพื่อนนั้นประสบความสำเร็จ	",
    withdraw_conditions: "	เงื่อนไขการถอน	",
    complete_all: "	ทำทั้งหมด 3 ขั้นตอนในการถอน	",
    not_yet: "	ยังไม่ได้เริ่มต้น	",
    complete: "	สมบูรณ์	",
    processing: "	กำลังประมวลผล	",
    remider_condition_1: "	ตำแหน่งทั้งหมดจะต้องปิดก่อนที่จะสามารถถอนได้	",
    remider_condition_2:
      "	เฉพาะตำแหน่งที่ใช้เวลานานกว่า 5 นาทีเท่านั้นที่จะได้รับการพิจารณาสำหรับเงื่อนไขเหล่านี้	",
    remider_condition_3: "	เมื่อการถอนสำเร็จแล้วบัญชีเครดิตจะถูกปิดอย่างถาวร	",
    conditions: "	เงื่อนไข	",
    close: "	ปิด	",
    banner_partner_title: "	เป็นพาร์ทเนอร์ IUX	",
    banner_partner_detail:
      "	เข้าร่วมในครั้งเดียวสำหรับโปรแกรมพันธมิตรทั้งหมดรับค่าคอมมิชชั่นอายุการใช้งานสูงถึง $ 10,000 และ 50%	",
    banner_join_partner: "เข้าร่วม IUX",
    banner_bonus_detail: `เปิดบัญชีการซื้อขายกับ IUX และรับโบนัสได้มากถึง 30 USD ฟรี`,
    banner_default_detail: `เทรด CFDs บนแพลตฟอร์มชั้นนำของโลกโดยตรงจากมือถือ`,
    banner_trade_detail: `เข้าถึงฟังก์ชั่นการสร้างแผนภูมิความเป็นเลิศของอุตสาหกรรมบนเครื่องที่ใช้งานง่ายและสร้างขึ้นเอง`,
    banner_trade_button: `เปิดแพลตฟอร์มการซื้อขาย`,
    congrat: "ยินดีด้วย",
    congrat_detail: "คุณได้สร้างบัญชีการซื้อขายสำเร็จแล้ว",
    congrat_demo_detail: "คุณได้สร้างบัญชีทดสอบสำเร็จ",
    account_number: "หมายเลขบัญชี",
    bonus_create_success:
      "เราขอให้คุณบันทึกรหัสผ่านการซื้อขายของคุณออกไปเพราะไม่สามารถส่งไปยังอีเมลของคุณได้เนื่องจากวัตถุประสงค์ด้านความปลอดภัย",
    bonus_button: "รับโบนัส",
    bonus_header: "โปรโมชัน",
    bonus_content:
      "โปรโมชันเปิดบัญชีครั้งแรกโปรโมชันเงินหรือโปรโมชันอื่น ๆ ที่จะเกิดขึ้นในอนาคตมันไม่สามารถถอนได้ในรูปแบบของเงินจริง",
    bonus_box_1: {
      text_1: `ฝากเงินครั้งแรก $10`,
      text_2: `รับเครดิค 30 USD หลังจากฝากเงิน`,
      text_3: `กำไรทั้งหมดสามารถถอนได้โดยมีเงื่อนไขตามนโยบาย`,
      text_4: `สำหรับลูกค้าใหม่`,
      text_5: `ไม่สามารถถอนเงินโบนัสได้`,
    },
    bonus_box_2: {
      text_1: `ฝากเงินครั้งแรก 10$`,
      text_2: `รับเครดิค 30 USD หลังจากฝากเงิน`,
      text_3: `กำไรทั้งหมดสามารถถอนได้โดยมีเงื่อนไขตามนโยบาย`,
      text_4: `สำหรับลูกค้าใหม่`,
      text_5: `ไม่สามารถถอนเงินโบนัสได้`,
    },
    banner: {
      mt5: `แพลตฟอร์มที่ทรงพลังสำหรับตลาด CFDs และ Exchange`,
    },
    btn: {
      mt5: `เปิดบัญชี  MT5`,
      mt4: `เปิดบัญชี  MT4`,
    },
    compare: {
      des_1: `IUX ได้ออกแบบแพลตฟอร์มของตนเพื่อให้แน่ใจว่าผู้ค้าทุกรายสามารถซื้อขายในรูปแบบที่ต้องการ ในสถานที่โปรดและบนอุปกรณ์ที่พวกเขาเลือก`,
      des_2: `ทั้ง MetaTrader 5 เป็นแพลตฟอร์มการซื้อขายที่ได้รับความนิยมและทรงพลัง ซึ่งอนุญาตให้ผู้ค้าเข้าถึงตลาดการเงิน และ IUX ได้ตรวจสอบให้แน่ใจว่าได้ใช้ประโยชน์จากคุณสมบัติที่ดีที่สุดของแต่ละรายการเพื่อมอบสภาพแวดล้อมการซื้อขายที่สมบูรณ์แบบ`,
      des_3: `เราแนะนำให้ใช้ IUX MT5 เพื่อการซื้อขายที่ราบรื่น และไม่สะดุดทุกการค้า`,
      titile_2: `การเปรียบเทียบคุณสมบัติ`,
      sub_titile_2: `แสดงความแตกต่าง`,
      mt5: {
        text_1: `เงื่อนไขการซื้อขายน้อยกว่า`,
        text_2: `ค่าธรรมเนียมการซื้อขายต่ำกว่า`,
        text_3: `ราคาซื้อขาย ราคาเปิด-ปิด ที่ตรงกับแผนภูมิ`,
        text_4: `Stop Level ต่ำกว่า`,
        text_5: `ซื้อขายได้ทันที`,
        text_6: `ผลิตภัณฑ์ที่มากกว่า`,
        text_7: `การเชื่อมต่อที่รวดเร็ว`,
        text_8: `ฟังก์ชันการซื้อขายที่มากกว่า`,
        text_9: `ประเภทบัญชีมากกว่า`,
      },
      mt4: {
        text_1: `เงื่อนไขการซื้อขายมากกว่า`,
        text_2: `ค่าธรรมเนียมการซื้อขายที่สูง`,
        text_3: `ราคาซื้อขาย ราคาเปิด-ปิด ที่ไม่ค่อยเสถียร`,
        text_4: `Stop Level สูงกว่า`,
        text_5: `การส่งคำสั่งที่ล่าช้า`,
        text_6: `ผลิตภัณฑ์ที่น้อย`,
        text_7: `การเชื่อมต่อที่ล้าช้า และไม่เสถียร`,
        text_8: `ฟังก์ชันการซื้อขายน้อย`,
        text_9: `จำกัดประเภทบัญชี`,
      },
    },
  },
  lo: {
    bonus_update: "ອັບເດດ",
    bonus_update_warning: "ກະລຸນາລໍຖ້າຈັກໜ່ອຍກ່ອນທີ່ຈະອັບເດດອີກຄັ້ງ.",
    received: "ໄດ້ຮັບ",
    no_deposit_bonus: "	ບໍ່ມີເງິນຝາກເງິນຝາກ	",
    bonus_30_detail: `      ເງິນຈໍານວນ 30 USD ຈະໄດ້ຮັບເງິນໂດຍອັດຕະໂນມັດເຂົ້າໃນບັນຊີຂອງທ່ານ
                  ເມື່ອເປີດບັນຊີຍິນດີຕ້ອນຮັບ. ທ່ານສາມາດຊື້ຂາຍກັບເງິນໄດ້ຮັບນີ້,
                  ແລະຜົນກໍາໄລໃດໆທີ່ທ່ານມີລາຍໄດ້ແມ່ນການຖອນໂດຍສະພາບການ. ຜຽນ
                  ກອງທຶນອິດສະຫຼະຂອງທ່ານເປັນຜົນກໍາໄລທີ່ແທ້ຈິງ!	`,
    starts: "	ເລີ່ມຕົ້ນ	",
    up_to: "	ເຖິງ	",
    commission_free: "	ຄະນະກໍາມະການຟຣີ	",
    deposit_bonus: "	ເງິນຝາກເງິນ	",
    deposit_bonus_35:
      "	ເສີມຂະຫຍາຍທຶນການຄ້າຂອງທ່ານດ້ວຍໂບນັດເງິນຝາກ {{value1}}%. ຝາກເງິນໃຫ້ທ່ານ, ແລະສູງສຸດ {{value2}} ໂດລາສະຫະລັດໃນບັນຊີຂອງທ່ານ. ການຄ້າດ້ວຍຄວາມຫມັ້ນໃຈແລະເຮັດໃຫ້ມີໂອກາດໃນຕະຫລາດການເງິນ.	",
    deposit_bonus_25:
      "	ເສີມຂະຫຍາຍທຶນການຄ້າຂອງທ່ານດ້ວຍໂບນັດເງິນຝາກ {{value1}}%. ຝາກຈໍານວນທີ່ທ່ານເລືອກ, ແລະໄດ້ຮັບເງິນຝາກປະຈໍາສູງສຸດ {{value2}} ໂດລາເຂົ້າໃນບັນຊີຂອງທ່ານ. ຢ່າລືມຢ່າພາດໂອກາດທີ່ດີທີ່ສຸດໃນການເພີ່ມທະວີທຶນການຄ້າຂອງທ່ານ.	",
    bonus_condition: "	ເງື່ອນໄຂໂບນັດ	",
    bonus_condition_detail:
      "	ທ່ານຈະໄດ້ຮັບເງິນໂບນັດທີ່ມີອັດຕາສ່ວນກັບຜົນລວມທີ່ຝາກໄວ້, ມີຂີດຈໍາກັດສູງສຸດຕາມທີ່ລະບຸ.	",
    bonus_notice_error: "ທ່ານບໍ່ໄດ້ຜ່ານທຸກເງື່ອນໄຂສໍາລັບການຖອນເງິນ",
    withdraw_option: "	ຖອນຕົວເລືອກ	",
    invite_more: "	ເຊີນ, ເຂົ້າຫຼາຍ.	",
    invite: "	ເຊີນ	",
    invite_person: "	ເຊີນຊວນຂຶ້ນ / ບຸກຄົນ	",
    maximum_withdraw: "	ການຖອນສູງສຸດ	",
    invie_link: "	ການເຊື່ອມໂຍງການເຊື້ອເຊີນ	",
    reminder: "	ຄໍາເຕືອນ	",
    remider_detail:
      "	ຜູ້ທີ່ຖືກເຊີນຕ້ອງໄດ້ຮັບການກວດສອບຄວາມເປັນຕົວຕົນຂອງພວກເຂົາແລະບັນຊີທະນາຄານຂອງພວກເຂົາແລະໄດ້ຮັບໂບນັດກ່ອນ. ເພາະສະນັ້ນ, ມັນຈະໄດ້ຮັບການພິຈາລະນາວ່າເພື່ອນທີ່ເຊີນແມ່ນປະສົບຜົນສໍາເລັດ.	",
    withdraw_conditions: "	ເງື່ອນໄຂການຖອນເງິນ	",
    complete_all: "	ສໍາເລັດທັງຫມົດ 3 ບາດກ້າວໃນການຖອນ	",
    not_yet: "	ຍັງບໍ່ໄດ້ເລີ່ມຕົ້ນ	",
    complete: "	ສົມບູນ	",
    processing: "	ການປຸງແຕ່ງ	",
    remider_condition_1: "	ຕໍາແຫນ່ງທັງຫມົດຕ້ອງຖືກປິດກ່ອນການຖອນເງິນສາມາດເຮັດໄດ້.	",
    remider_condition_2:
      "	ມີແຕ່ຕໍາແຫນ່ງທີ່ຍາວກວ່າ 5 ນາທີຈະຖືກພິຈາລະນາສໍາລັບເງື່ອນໄຂເຫຼົ່ານີ້.	",
    remider_condition_3:
      "	ເມື່ອການຖອນເງິນປະສົບຜົນສໍາເລັດແລ້ວບັນຊີສິນເຊື່ອຈະຖືກປິດຢ່າງຖາວອນ.	",
    conditions: "	ສັນຍາລັກ	",
    close: "	ປິດ	",
    banner_partner_title: "	ເຂົ້າຮ່ວມໂຄງການຄູ່ຮ່ວມງານກັບ IUX  ",
    banner_partner_detail:
      "	ເຂົ້າຮ່ວມໃນເວລາດຽວກັນສໍາລັບໂປແກຼມຄູ່ຮ່ວມງານທັງຫມົດ, ມີລາຍໄດ້ສູງເຖິງ 10,000 ໂດລາແລະ 50% ຂອງຄ່ານາຍໜ້າຕະຫຼອດຊີວິດ.	",
    banner_join_partner: "ເຂົ້າຮ່ວມໂຄງການຄູ່ຮ່ວມງານ",
    banner_bonus_detail: `ເປີດບັນຊີການຊື້ຂາຍກັບ IUX ຮັບໂບນັດ 30 USD`,
    banner_default_detail: `ການຄ້າ CFDs ໃນເວທີນໍາຫນ້າໃນໂລກຂອງໂລກໂດຍກົງຈາກມືຖື.`,
    banner_trade_detail: `ເຂົ້າເຖິງການເຮັດວຽກກ່ຽວກັບຕາຕະລາງທີ່ດີເລີດຂອງອຸດສາຫະກໍາກ່ຽວກັບຜູ້ໃຊ້ທີ່ເປັນມິດກັບຜູ້ໃຊ້, ສ້າງແບບທີ່ເຫມາະສົມ.`,
    banner_trade_button: `ເປີດເວທີການຄ້າຂາຍ`,
    congrat: "	ຄໍາສະແດງຄວາມຍິນດີ	",
    congrat_detail: "	ທ່ານໄດ້ສ້າງບັນຊີການຄ້າທີ່ປະສົບຜົນສໍາເລັດແລ້ວ	",
    congrat_demo_detail: "ທ່ານໄດ້ສ້າງບັນຊີ demo ຢ່າງສໍາເລັດຜົນແລ້ວ",
    account_number: "	ເລກບັນຊີ	",
    bonus_create_success:
      "	ພວກເຮົາຂໍສະແດງຄວາມກະລຸນາທີ່ທ່ານປະຫຍັດລະຫັດຜ່ານຂອງການຊື້ຂາຍຂອງທ່ານທີ່ບໍ່ສາມາດສົ່ງໄປຫາອີເມວຂອງທ່ານໄດ້ເນື່ອງຈາກຈຸດປະສົງຄວາມປອດໄພ.	",
    bonus_button: "ຮັບໂບນັດ",
    bonus_header: "ໂປຣໂມຊັ່ນ",
    bonus_content:
      "ໂປຼໂມຊັ່ນເປີດບັນຊີ ທຳ ອິດການສົ່ງເສີມການເງີນຫລືໂປໂມຊັ່ນອື່ນໆທີ່ຈະເກີດຂື້ນໃນອະນາຄົດມັນບໍ່ສາມາດຖອນໄດ້ໃນຮູບແບບເງິນແທ້",
    bonus_box_1: {
      text_1: `ບໍ່ຈຳເປັນຕ້ອງມີເງິນຝາກ`,
      text_2: `ເງິນໂບນັດຈະເຂົ້າໃນບັນຊີຂອງທ່ານອັດຕະໂນມັດ`,
      text_3: `ກຳ ຈັດທັງສາລະຄອນຕອນໄດ້ໂດຍມີເງີນຕາມນິຍາຍ`,
      text_4: `ສາມາດໃຊ້ໄດ້ກັບລູກຄ້າໃຫມ່`,
      text_5: `ໂບນັດບໍ່ສາມາດຖອນໄດ້`,
    },
    bonus_box_2: {
      text_1: `ຝາກຄັ້ງທຳອິດ 10$`,
      text_2: `ໄດ້ຮັບສິນເຊື່ອ 30 USD ຫລັງຈາກໄດ້ຝາກເງິນ`,
      text_3: `ຜົນກຳໄລທັງໝົດ ແມ່ນຖືກຖອນຄືນໂດຍບໍ່ມີເງື່ອນໄຂ`,
      text_4: `ສຳລັບລູກຄ້າໃໝ່`,
      text_5: `ໂບນັດບໍ່ສາມາດຖອນໄດ້`,
    },
    banner: {
      mt5: `ແພລະຕະຟອມທີ່ມີປະສິດທິພາບສໍາລັບຕະຫຼາດແລກປ່ຽນເງິນຕາ CFDs`,
    },
    btn: {
      mt5: `ເປີດບັນຊີ MT5`,
      mt4: `ເປີດບັນຊີ MT4`,
    },
    compare: {
      des_1: `IUX ໄດ້ສ້າງເວທີການຄ້າຂອງພວກເຮົາເອງເພື່ອໃຫ້ແນ່ໃຈວ່າລູກຄ້າຂອງພວກເຮົາສາມາດຊື້ຂາຍໄດ້ທຸກບ່ອນແລະທຸກເວລາທີ່ພວກເຂົາຕ້ອງການ.`,
      des_2: `ທັງ MetaTrader 4 ແລະ MetaTrader 5 ເປັນແພລດຟອມທີ່ມີປະສິດທິພາບແລະເປັນທີ່ນິຍົມເຊິ່ງຊ່ວຍໃຫ້ພໍ່ຄ້າສາມາດເຂົ້າເຖິງຕະຫຼາດການເງິນ, ແລະ IUX ຮັບປະກັນວ່າຈະນໍາເອົາລັກສະນະດີທີ່ສຸດຂອງແຕ່ລະອັນມາໃຫ້ສະພາບແວດລ້ອມການຊື້ຂາຍທີ່ສົມບູນແບບ.`,
      des_3: `ພວກເຮົາແນະນໍາໃຫ້ຊື້ຂາຍໃນ IUX MT5 ສໍາລັບການຊື້ຂາຍທີ່ລຽບງ່າຍແລະບໍ່ມີບັນຫາ.`,
      titile_2: `ການປຽບທຽບລັກສະນະ ໃນທັງສອງເວທີ`,
      sub_titile_2: `\n`,
      mt5: {
        text_1: `ເງື່ອນໄຂການຊື້ຂາຍ ໜ້ອຍ ລົງ`,
        text_2: `ຄ່າທໍານຽມການຊື້ຂາຍທີ່ຖືກກວ່າ`,
        text_3: `ລາຄາປະມູນ-ຖາມທີ່ຖືກຕ້ອງ`,
        text_4: `ລະດັບຢຸດຕ່ໍາ`,
        text_5: `ການຊື້ຂາຍທັນທີ (ບໍ່ຕ້ອງການ)`,
        text_6: `ຜະລິດຕະພັນການຄ້າເພີ່ມເຕີມ`,
        text_7: `ການເຊື່ອມຕໍ່ທີ່ໄວແລະstableັ້ນຄົງ`,
        text_8: `ຟັງຊັນການຊື້ຂາຍຫຼາຍຂຶ້ນ`,
        text_9: `ປະເພດບັນຊີເພີ່ມເຕີມ`,
      },
      mt4: {
        text_1: `ເງື່ອນໄຂການຊື້ຂາຍຫຼາຍຂຶ້ນ`,
        text_2: `ຄ່າທໍານຽມການຊື້ຂາຍລາຄາແພງ`,
        text_3: `ລາຄາການຊື້ຂາຍເປີດ-ປິດລາຄາທີ່ບໍ່stableັ້ນຄົງ`,
        text_4: `ລະດັບການຢຸດທີ່ສູງກວ່າ`,
        text_5: `ຮຽກຮ້ອງເລື້ອຍ`,
        text_6: `ຜະລິດຕະພັນຊື້ຂາຍ ໜ້ອຍ ລົງ`,
        text_7: `ການເຊື່ອມຕໍ່ບໍ່ສະຖຽນ`,
        text_8: `ໜ້າ ທີ່ການຊື້ຂາຍ ໜ້ອຍ ລົງ`,
        text_9: `ປະເພດບັນຊີຈໍາກັດ`,
      },
    },
  },
  en: {
    bonus_update: "Update",
    bonus_update_warning: "Please wait a moment before updating again.",
    received: "Received",
    no_deposit_bonus: "	No deposit bonus	",
    bonus_30_detail: `	      Instant 30 USD bonus will be automatically credited to your account
                  when opening a Welcome account. You can trade with this bonus,
                  and any profits you earn are withdrawable by conditions. Turn
                  your free-fund into real profits!	`,
    starts: "	starts	",
    up_to: "	up to	",
    commission_free: "	Commission Free	",
    deposit_bonus: "	Deposit bonus	",
    deposit_bonus_35:
      "	Boost your trading capital with a {{value1}}% deposit bonus. Deposit an amount of your choice, and get maximum {{value2}} USD of deposit bonus to your account. Trade with confidence and seize opportunities in the financial markets.	",
    deposit_bonus_25:
      "	Boost your trading capital with a {{value1}}% deposit bonus. Deposit an amount of your choice, and get maximum {{value2}} USD of deposit bonus to your account. Don't miss out on this fantastic opportunity to supercharge your trading capital.	",
    bonus_condition: "	Bonus condition	",
    bonus_condition_detail:
      "	You will receive a bonus amount that is proportional to the deposited sum, with a maximum limit as specified.	",
    bonus_notice_error:
      "You have not pass all the conditions for withdrawing the bonus",
    withdraw_option: "	Withdraw Options	",
    invite_more: "	Invite more, get more.	",
    invite: "	invite	",
    invite_person: "	Invite more\n/Person	",
    maximum_withdraw: "	Maximum\nWithdrawal	",
    invie_link: "	Invitation link	",
    reminder: "	Reminder	",
    remider_detail:
      "	The invited must successfully verify their identity and bank account and get the bonus first. Therefore, it will be considered that inviting friends is successful.	",
    withdraw_conditions: "	Withdrawal Conditions	",
    complete_all: "	Complete all 3 steps to withdrawal	",
    not_yet: "	Not yet started	",
    complete: "	Complete	",
    processing: "	Processing	",
    remider_condition_1:
      "	All positions must be closed before withdrawal can be made.	",
    remider_condition_2:
      "	Only positions that last longer than 5 minutes will be considered for these conditions.	",
    remider_condition_3:
      "	Once the withdrawal is successful, the credit account will be closed permanently.	",
    conditions: "	Conditions	",
    close: "	Close	",
    banner_partner_title: "	Become IUX Partner    ",
    banner_partner_detail:
      "	Join at once for all Partner Programs, earn up to $10,000 and 50% lifetime commission.	",
    banner_join_partner: "IUX Partner",
    banner_bonus_detail: `Open a live trading account with IUX and get up to 30 USD bonus free`,
    banner_default_detail: `Trade CFDs on the world’s leading platforms directly from mobile.`,
    banner_trade_detail: `Access the industry’s excellence charting functionality on the user-friendly, custom-built.`,
    banner_trade_button: `Open Trading Platform`,
    congrat: "	Congratulations!	",
    congrat_detail: "	You have successfully created trading account	",
    congrat_demo_detail: "You have successfully created demo account",
    account_number: "	Account number	",
    bonus_create_success:
      "	We kindly request that you save your trading password rift away as it cannot be sent to your email due to security purposes.	",
    bonus_button: "Get Bonus",
    bonus_header: "PROMOTIONS",
    bonus_content: `The first account promotion, money promotion or any other promotion that will take in the future, it cannot be withdraw in the form of real money.`,
    bonus_box_1: {
      text_1: `First deposit 10 $`,
      text_2: `Get 30 $ credit after deposit.`,
      text_3: `All profits can be withdrawn with conditions according to the policy`,
      text_4: `For new customers`,
      text_5: `The bonus cannot be withdraw`,
    },
    bonus_box_2: {
      text_1: `First deposit 20 $`,
      text_2: `Receive a $ 50 credit after depositing funds`,
      text_3: `All profits can be withdrawn without conditions.`,
      text_4: `For new customers`,
      text_5: `Cannot withdraw bonus funds`,
    },
    banner: {
      mt5: `A powerful platform for CFDs and Exchange markets`,
    },
    btn: {
      mt5: `Open MT5 Account`,
      mt4: `Open MT4 Account`,
    },
    compare: {
      des_1: `IUX has created our own trading platform to make sure our clients can trade wherever and whenever they want.`,
      des_2: `Both  MetaTrader 5 are powerful and popular platforms which allow traders to access financial markets, and IUX guaranteed to bring  the best features of each to provide the perfect trading environment.`,
      des_3: `We recommend trading on IUX MT5 for smooth and trouble-free trading.`,
      titile_2: `Features comparison on both platforms`,
      sub_titile_2: `Show the difference`,
      mt5: {
        text_1: `Lesss trading conditions`,
        text_2: `Cheaper trading fees`,
        text_3: `Accurate Bid-Ask price`,
        text_4: `Lower Stop Level`,
        text_5: `Immediate trading (No requote)`,
        text_6: `More trading products`,
        text_7: `Fast and stable connection`,
        text_8: `More trading functions`,
        text_9: `More account type`,
      },
      mt4: {
        text_1: `More trading conditions`,
        text_2: `Expensive trading fees`,
        text_3: `Unstable Bid-Ask price`,
        text_4: `Higher Stop Level`,
        text_5: `Frequently requote`,
        text_6: `Less trading products`,
        text_7: `Unstable connection`,
        text_8: `Less trading functions`,
        text_9: `Limited account types`,
      },
    },
  },
  hi: {
    bonus_update: "अद्यतन",
    bonus_update_warning:
      "दोबारा अपडेट करने से पहले कृपया एक क्षण प्रतीक्षा करें।",
    received: "प्राप्त",
    no_deposit_bonus: "	कोई जमा बोनस नहीं	",
    bonus_30_detail: `      तत्काल 30 USD बोनस आपके खाते में स्वचालित रूप से जमा हो जाएगा
                  स्वागत खाता खोलते समय। आप इस बोनस के साथ व्यापार कर सकते हैं,
                  और आपके द्वारा अर्जित किया गया कोई भी मुनाफा परिस्थितियों से पीछे हट जाता है। मोड़
                  असली मुनाफे में आपका फ्री-फंड!`,
    starts: "	प्रारंभ होगा	",
    up_to: "	तक	",
    commission_free: "	आयोग मुक्त	",
    deposit_bonus: "	जमा बोनस	",
    deposit_bonus_35:
      "	{{value1}}% डिपॉजिट बोनस के साथ अपनी ट्रेडिंग कैपिटल को बढ़ावा दें। अपनी पसंद की एक राशि जमा करें, और अपने खाते में अधिकतम {{value2}} USD जमा बोनस प्राप्त करें। विश्वास के साथ व्यापार और वित्तीय बाजारों में अवसरों को जब्त करना।	",
    deposit_bonus_25:
      "	{{value1}}% डिपॉजिट बोनस के साथ अपनी ट्रेडिंग कैपिटल को बढ़ावा दें। अपनी पसंद की एक राशि जमा करें, और अपने खाते में अधिकतम {{value2}} USD जमा बोनस प्राप्त करें। अपनी ट्रेडिंग कैपिटल को सुपरचार्ज करने के लिए इस शानदार अवसर को याद न करें।	",
    bonus_condition: "	बोनस शर्त	",
    bonus_condition_detail:
      "	आपको एक बोनस राशि प्राप्त होगी जो जमा राशि के लिए आनुपातिक है, निर्दिष्ट के रूप में अधिकतम सीमा के साथ।	",
    bonus_notice_error:
      "आपने बोनस वापस लेने के लिए सभी शर्तों को पारित नहीं किया है",
    withdraw_option: "	विकल्प वापस लेना	",
    invite_more: "	अधिक आमंत्रित करें, अधिक प्राप्त करें।	",
    invite: "	आमंत्रित करना	",
    invite_person: "	अधिक/ व्यक्ति को आमंत्रित करें	",
    maximum_withdraw: "	अधिकतम निकासी	",
    invie_link: "	निमंत्रण लिंक	",
    reminder: "	अनुस्मारक	",
    remider_detail:
      "	आमंत्रित को अपनी पहचान और बैंक खाते को सफलतापूर्वक सत्यापित करना चाहिए और पहले बोनस प्राप्त करना चाहिए। इसलिए, यह माना जाएगा कि दोस्तों को आमंत्रित करना सफल है।	",
    withdraw_conditions: "	निकासी की स्थिति	",
    complete_all: "	वापस लेने के लिए सभी 3 चरणों को पूरा करें	",
    not_yet: "	अभी तक शुरू नहीं किया	",
    complete: "	पूरा	",
    processing: "	प्रसंस्करण	",
    remider_condition_1: "	निकासी से पहले सभी पदों को बंद किया जाना चाहिए।	",
    remider_condition_2:
      "	केवल 5 मिनट से अधिक समय तक चलने वाले पदों को इन स्थितियों के लिए माना जाएगा।	",
    remider_condition_3:
      "	एक बार वापसी सफल होने के बाद, क्रेडिट खाता स्थायी रूप से बंद हो जाएगा।	",
    conditions: "	स्थितियाँ	",
    close: "	बंद करना	",
    banner_partner_title: "	IUX पार्टनर बनें ",
    banner_partner_detail:
      "	सभी भागीदार कार्यक्रमों के लिए एक बार में शामिल हों, $ 10,000 और 50% आजीवन आयोग कमाएं।	",
    banner_join_partner: "साथी से जुड़ें",
    banner_bonus_detail: `IUX के साथ एक लाइव ट्रेडिंग अकाउंट खोलें और 30 USD बोनस फ्री तक प्राप्त करें`,
    banner_default_detail: `सीधे मोबाइल से दुनिया के प्रमुख प्लेटफार्मों पर सीएफडी का व्यापार करें।`,
    banner_trade_detail: `उपयोगकर्ता के अनुकूल, कस्टम-निर्मित पर उद्योग की उत्कृष्टता चार्टिंग कार्यक्षमता का उपयोग करें।`,
    banner_trade_button: `ओपन ट्रेडिंग प्लेटफ़ॉर्म`,
    congrat: "	बधाई हो	",
    congrat_detail: "	आपने सफलतापूर्वक ट्रेडिंग अकाउंट बनाया है	",
    congrat_demo_detail: "	आपने सफलतापूर्वक डेमो खाता बनाया है",
    account_number: "	खाता संख्या	",
    bonus_create_success:
      "	हम कृपया अनुरोध करते हैं कि आप अपने ट्रेडिंग पासवर्ड रिफ्ट को बचाते हैं क्योंकि इसे सुरक्षा उद्देश्यों के कारण आपके ईमेल पर नहीं भेजा जा सकता है।	",
    bonus_button: `बोनस प्राप्त करें`,
    bonus_header: `प्रचार`,
    bonus_content: `पहला अकाउंट प्रमोशन, मनी प्रमोशन या कोई अन्य प्रमोशन जो भविष्य में होगा, उसे वास्तविक धन के रूप में नहीं निकाला जा सकता है।`,
    bonus_box_1: {
      text_1: `सबसे पहले 10$ जमा करें`,
      text_2: `जमा करने के बाद 30$ क्रेडिट प्राप्त करें।`,
      text_3: `पॉलिसी के अनुसार शर्तों के साथ सारा मुनाफा निकाला जा सकता है`,
      text_4: `नए ग्राहकों के लिए`,
      text_5: `बोनस वापस नहीं लिया जा सकता`,
    },
    bonus_box_2: {
      text_1: `सबसे पहले 20$ जमा करें`,
      text_2: `धनराशि जमा करने के बाद $50 का क्रेडिट प्राप्त करें`,
      text_3: `सारा मुनाफा बिना किसी शर्त के निकाला जा सकता है।`,
      text_4: `नए ग्राहकों के लिए`,
      text_5: `बोनस फंड नहीं निकाल सकते`,
    },
    banner: {
      mt5: `सीएफडी और एक्सचेंज बाजारों के लिए एक शक्तिशाली मंच`,
    },
    btn: {
      mt5: `MT5 खाता खोलें`,
      mt4: `MT4 खाता खोलें`,
    },
    compare: {
      des_1: `IUX मार्केट्स ने यह सुनिश्चित करने के लिए अपना खुद का ट्रेडिंग प्लेटफॉर्म बनाया है कि हमारे ग्राहक जहां चाहें और जब चाहें व्यापार कर सकें।`,
      des_2: `मेटाट्रेडर 5 दोनों शक्तिशाली और लोकप्रिय प्लेटफ़ॉर्म हैं जो व्यापारियों को वित्तीय बाज़ारों तक पहुंचने की अनुमति देते हैं, और आईयूएक्स मार्केट्स सही व्यापारिक वातावरण प्रदान करने के लिए प्रत्येक की सर्वोत्तम सुविधाएँ लाने की गारंटी देता है।`,
      des_3: `हम सुचारू और परेशानी मुक्त व्यापार के लिए IUX मार्केट्स MT5 पर व्यापार करने की सलाह देते हैं।`,
      titile_2: `दोनों प्लेटफार्मों पर सुविधाओं की तुलना`,
      sub_titile_2: `अंतर दिखाओ`,
      mt5: {
        text_1: `कम व्यापारिक स्थितियाँ`,
        text_2: `सस्ता ट्रेडिंग शुल्क`,
        text_3: `सटीक बोली-पूछो कीमत`,
        text_4: `निचला स्टॉप लेवल`,
        text_5: `तत्काल व्यापार (कोई अनुरोध नहीं)`,
        text_6: `अधिक व्यापारिक उत्पाद`,
        text_7: `तेज़ और स्थिर कनेक्शन`,
        text_8: `अधिक व्यापारिक कार्य`,
        text_9: `अधिक खाता प्रकार`,
      },
      mt4: {
        text_1: `अधिक व्यापारिक स्थितियाँ`,
        text_2: `महंगी ट्रेडिंग फीस`,
        text_3: `अस्थिर बोली-पूछो मूल्य`,
        text_4: `उच्चतर स्टॉप लेवल`,
        text_5: `बार-बार उद्धृत करें`,
        text_6: `कम व्यापारिक उत्पाद`,
        text_7: `अस्थिर कनेक्शन`,
        text_8: `कम व्यापारिक कार्य`,
        text_9: `सीमित खाता प्रकार`,
      },
    },
  },
  ms: {
    bonus_update: "Kemas kini",
    bonus_update_warning: "Sila tunggu sebentar sebelum mengemas kini semula.",
    received: "menerima",
    no_deposit_bonus: "	Tiada bonus deposit	",
    bonus_30_detail: `     Bonus 30 USD segera akan dikreditkan secara automatik ke akaun anda
                  semasa membuka akaun selamat datang. Anda boleh berdagang dengan bonus ini,
                  dan apa -apa keuntungan yang anda perolehi akan ditarik balik oleh syarat. Giliran
                  Dana percuma anda menjadi keuntungan sebenar!`,
    starts: "	bermula	",
    up_to: "	sehingga	",
    commission_free: "	Suruhanjaya Percuma	",
    deposit_bonus: "	Bonus deposit	",
    deposit_bonus_35:
      "	Meningkatkan modal perdagangan anda dengan bonus deposit {{value1}}%. Deposit jumlah pilihan anda, dan dapatkan maksimum {{value2}} USD bonus deposit ke akaun anda. Perdagangan dengan keyakinan dan merebut peluang di pasaran kewangan.	",
    deposit_bonus_25:
      "	Meningkatkan modal perdagangan anda dengan bonus deposit {{value1}}%. Deposit jumlah pilihan anda, dan dapatkan maksimum {{value2}} USD bonus deposit ke akaun anda. Jangan ketinggalan peluang hebat ini untuk menanggung modal perdagangan anda.	",
    bonus_condition: "	Keadaan bonus	",
    bonus_condition_detail:
      "	Anda akan menerima jumlah bonus yang berkadar dengan jumlah yang disimpan, dengan had maksimum seperti yang ditentukan.	",
    bonus_notice_error:
      "Anda belum lulus semua syarat untuk mengeluarkan bonus",
    withdraw_option: "	Menarik balik pilihan	",
    invite_more: "	Jemput lebih banyak, dapatkan lebih banyak.	",
    invite: "	jemput	",
    invite_person: "	Jemput lebih banyak/ orang	",
    maximum_withdraw: "	Pengeluaran maksimum	",
    invie_link: "	Pautan jemputan	",
    reminder: "	Peringatan	",
    remider_detail:
      "	Jemput mesti berjaya mengesahkan identiti dan akaun bank mereka dan mendapatkan bonus terlebih dahulu. Oleh itu, ia akan dianggap bahawa rakan -rakan yang menjemput berjaya.	",
    withdraw_conditions: "	Keadaan pengeluaran	",
    complete_all: "	Lengkapkan semua 3 langkah untuk pengeluaran	",
    not_yet: "	Belum bermula	",
    complete: "	Lengkap	",
    processing: "	Pemprosesan	",
    remider_condition_1:
      "	Semua jawatan mesti ditutup sebelum pengeluaran boleh dibuat.	",
    remider_condition_2:
      "	Hanya kedudukan yang bertahan lebih lama daripada 5 minit akan dipertimbangkan untuk keadaan ini.	",
    remider_condition_3:
      "	Sebaik sahaja pengeluaran berjaya, akaun kredit akan ditutup secara kekal.	",
    conditions: "	Keadaan	",
    close: "	Tutup	",
    banner_partner_title: "	Menjadi rakan kongsi IUX	",
    banner_partner_detail:
      "	Sertai sekaligus untuk semua program rakan kongsi, dapatkan sehingga $ 10,000 dan 50% komisen seumur hidup.	",
    banner_join_partner: "Menjadi Rakan Kongsi kami",
    banner_bonus_detail: `Buka akaun dagangan langsung dengan pasaran IUX dan dapatkan sehingga 30 USD bonus percuma`,
    banner_default_detail: `Perdagangan CFD di platform terkemuka di dunia secara langsung dari Mobile.`,
    banner_trade_detail: `Akses kefungsian mencatatkan kecemerlangan industri pada mesra pengguna, dibina khas.`,
    banner_trade_button: `Platform Perdagangan Terbuka`,
    congrat: "	Tahniah	",
    congrat_detail: "	Anda telah berjaya membuat akaun perdagangan	",
    congrat_demo_detail: "Anda telah berjaya membuat akaun demo",
    account_number: "	Nombor akaun	",
    bonus_create_success:
      "	Kami meminta anda menyimpan kata laluan dagangan anda kerana ia tidak dapat dihantar ke e -mel anda kerana tujuan keselamatan.	",
    bonus_button: "Dapatkan Bonus",
    bonus_header: "promosi",
    bonus_content:
      "Promosi pembukaan akaun pertama, promosi wang atau sebarang promosi masa depan yang lain, ia tidak boleh dikeluarkan dalam bentuk wang sebenar.",
    bonus_box_1: {
      text_1: `deposit pertama 10 $`,
      text_2: `Terima 30 USD kredit selepas mendepositkan dana`,
      text_3: `Semua keuntungan boleh dikeluarkan tertakluk kepada syarat polisi`,
      text_4: `Untuk pelanggan baru`,
      text_5: `tidak boleh mengeluarkan wang bonus`,
    },
    bonus_box_2: {
      text_1: `deposit pertama 20 $`,
      text_2: `Terima $ 50 kredit selepas mendepositkan dana`,
      text_3: `Semua keuntungan boleh dikeluarkan tertakluk kepada syarat polisi`,
      text_4: `Untuk pelanggan baru`,
      text_5: `tidak boleh mengeluarkan wang bonus`,
    },
    banner: {
      mt5: `Platform Berkuasa untuk Pasaran CFDs dan Pertukaran`,
    },
    btn: {
      mt5: `Buka Akaun MT5 `,
      mt4: `Buka Akaun MT4 `,
    },
    compare: {
      des_1: `IUX platform telah direka untuk memastikan setiap pedagang boleh berdagang mengikut gaya pilihan mereka, di tempat kegemaran mereka dan pada peranti pilihan mereka.`,
      des_2: `Kedua-dua MetaTrader 4 dan MetaTrader 5 adalah platform dagangan yang popular dan berkuasa. Ini membolehkan pedagang mengakses pasaran kewangan, dan IUX telah memastikan pemanfaatan  terbaik dari setiap feature supaya dapat menyediakan persekitaran dagangan yang sempurna.`,
      des_3: `Kami mengesyorkan penggunaanIUX MT5 untuk dagangan yang lancar dan tidak tersandung pada setiap perdagangan.`,
      titile_2: `Perbandingan ciri`,
      sub_titile_2: `Tunjukkan perbezaannya`,
      mt5: {
        text_1: `Lebih Sedikit syarat dagangan`,
        text_2: `Yuran dagangan yang lebih rendah`,
        text_3: `Harga dagangan, Harga buka-tutup sepadan dengan carta`,
        text_4: `Aras Hentian Bawah`,
        text_5: `Perdagangan segera`,
        text_6: `Lebih banyak produk`,
        text_7: `Sambungan pantas`,
        text_8: `Berbagai fungsi perdagangan`,
        text_9: `Lebih banyak jenis akaun`,
      },
      mt4: {
        text_1: `Lebih banyak syarat dagangan`,
        text_2: `Yuran dagangan yang lebih tinggi`,
        text_3: `Harga dagangan, Harga buka-tutup tidak stabil`,
        text_4: `Aras Hentian Atas`,
        text_5: `Sering direquote`,
        text_6: `Lebih sedikit produk`,
        text_7: `Penghantaran pesanan sering tertunda`,
        text_8: `Lebih sedikit fungsi perdagangan`,
        text_9: `Jenis akaun terhad`,
      },
    },
  },
  id: {
    bonus_update: "Memperbarui",
    bonus_update_warning: "Harap tunggu sebentar sebelum memperbarui lagi.",
    received: "diterima",
    no_deposit_bonus: "	Tidak ada bonus setoran	",
    bonus_30_detail: `      Bonus instan 30 USD akan dikreditkan secara otomatis ke akun Anda
                  Saat membuka akun selamat datang. Anda dapat berdagang dengan bonus ini,
                  dan keuntungan apa pun yang Anda peroleh dapat ditarik berdasarkan kondisi. Berbelok
                  dana gratis Anda menjadi keuntungan nyata!`,
    starts: "	dimulai	",
    up_to: "	hingga	",
    commission_free: "	Komisi gratis	",
    deposit_bonus: "	Bonus setoran	",
    deposit_bonus_35:
      "	Tingkatkan modal perdagangan Anda dengan bonus setoran {{value1}}%. Setor sejumlah pilihan Anda, dan dapatkan maksimum {{value2}} USD bonus setoran ke akun Anda. Berdagang dengan percaya diri dan mengambil peluang di pasar keuangan.	",
    deposit_bonus_25:
      "	Tingkatkan modal perdagangan Anda dengan bonus setoran {{value1}}%. Setor sejumlah pilihan Anda, dan dapatkan maksimum {{value2}} USD bonus setoran ke akun Anda. Jangan lewatkan kesempatan fantastis ini untuk menambah modal perdagangan Anda.	",
    bonus_condition: "	Kondisi bonus	",
    bonus_condition_detail:
      "	Anda akan menerima jumlah bonus yang sebanding dengan jumlah yang disimpan, dengan batas maksimum seperti yang ditentukan.	",
    bonus_notice_error: "Anda belum melewati semua kondisi untuk menarik bonus",
    withdraw_option: "	Opsi penarikan	",
    invite_more: "	Undang lebih banyak, dapatkan lebih banyak.	",
    invite: "	mengundang	",
    invite_person: "	Undang lebih banyak/ orang	",
    maximum_withdraw: "	Penarikan maksimum	",
    invie_link: "	Tautan undangan	",
    reminder: "	Pengingat	",
    remider_detail:
      "	Yang diundang harus berhasil memverifikasi identitas dan rekening bank mereka dan mendapatkan bonus terlebih dahulu. Oleh karena itu, akan dipertimbangkan bahwa teman -teman yang mengundang berhasil.	",
    withdraw_conditions: "	Kondisi penarikan	",
    complete_all: "	Selesaikan semua 3 langkah untuk penarikan	",
    not_yet: "	Belum dimulai	",
    complete: "	Menyelesaikan	",
    processing: "	Pengolahan	",
    remider_condition_1:
      "	Semua posisi harus ditutup sebelum penarikan dapat dilakukan.	",
    remider_condition_2:
      "	Hanya posisi yang bertahan lebih dari 5 menit yang akan dipertimbangkan untuk kondisi ini.	",
    remider_condition_3:
      "	Setelah penarikan berhasil, akun kredit akan ditutup secara permanen.	",
    conditions: "	Kondisi	",
    close: "	Menutup	",
    banner_partner_title: "	Menjadi mitra IUX	",
    banner_partner_detail:
      "	Bergabunglah sekaligus untuk semua program mitra, hasilkan hingga $ 10,000 dan 50% komisi seumur hidup.	",
    banner_join_partner: "Menjadi mitra IUX",
    banner_bonus_detail: `Buka akun perdagangan langsung dengan IUX dan dapatkan hingga 30 USD bonus gratis`,
    banner_default_detail: `Perdagangkan CFD di platform terkemuka dunia langsung dari ponsel.`,
    banner_trade_detail: `Akses fungsionalitas charting keunggulan industri pada yang ramah pengguna, dibuat khusus.`,
    banner_trade_button: `Buka Platform Perdagangan`,
    congrat: "	Selamat	",
    congrat_detail: "	Anda telah berhasil membuat akun perdagangan	",
    congrat_demo_detail: "Anda telah berhasil membuat akun demo",
    account_number: "	Nomor akun	",
    bonus_create_success:
      "	Kami dengan baik meminta Anda menyimpan keretakan kata sandi perdagangan Anda karena tidak dapat dikirim ke email Anda karena tujuan keamanan.	",
    bonus_button: "Dapatkan Bonus",
    bonus_header: "promosi",
    bonus_content:
      "Pertama pembukaan rekening promosi promosi Uang Atau promosi lain yang akan terjadi di masa depan Hal ini tidak dapat ditarik dalam bentuk uang riil.",
    bonus_box_1: {
      text_1: `deposit pertama 10 $`,
      text_2: `Menerima kredit 30 USD setelah menyetorkan dana`,
      text_3: `All profits can be withdrawn with conditions according to the policy`,
      text_4: `Untuk pelanggan baru`,
      text_5: `tidak bisa menarik dana bonus`,
    },
    bonus_box_2: {
      text_1: `deposit pertama 20 $`,
      text_2: `Menerima kredit $ 50 setelah menyetorkan dana`,
      text_3: `All profits can be withdrawn with conditions according to the policy`,
      text_4: `Untuk pelanggan baru`,
      text_5: `tidak bisa menarik dana bonus`,
    },
    banner: {
      mt5: `A powerful platform for CFDs and Exchange markets`,
    },
    btn: {
      mt5: `Open MT5 Account`,
      mt4: `Open MT4 Account`,
    },
    compare: {
      des_1: `IUX has created our own trading platform to make sure our clients can trade wherever and whenever they want.`,
      des_2: `Both  MetaTrader 5 are powerful and popular platforms which allow traders to access financial markets, and IUX guaranteed to bring  the best features of each to provide the perfect trading environment.`,
      des_3: `We recommend trading on IUX MT5 for smooth and trouble-free trading.`,
      titile_2: `Features comparison on both platforms`,
      sub_titile_2: `Show the difference`,
      mt5: {
        text_1: `Lesss trading conditions`,
        text_2: `Cheaper trading fees`,
        text_3: `Accurate Bid-Ask price`,
        text_4: `Lower Stop Level`,
        text_5: `Immediate trading (No requote)`,
        text_6: `More trading products`,
        text_7: `Fast and stable connection`,
        text_8: `More trading functions`,
        text_9: `More account type`,
      },
      mt4: {
        text_1: `More trading conditions`,
        text_2: `Expensive trading fees`,
        text_3: `Unstable Bid-Ask price`,
        text_4: `Higher Stop Level`,
        text_5: `Frequently requote`,
        text_6: `Less trading products`,
        text_7: `Unstable connection`,
        text_8: `Less trading functions`,
        text_9: `Limited account types`,
      },
    },
  },
  zh: {
    bonus_update: "Update",
    bonus_update_warning: "Please wait a moment before updating again.",
    received: "已收到",
    no_deposit_bonus: "	沒有存款獎金	",
    bonus_30_detail: `      即時30 USD的獎金將自動記入您的帳戶
                  開設歡迎帳戶時。您可以通過此獎金進行交易，
                  您賺取的任何利潤都是可以根據條件提取的。轉動
                  您的自由基金成為真正的利潤！`,
    starts: "	開始	",
    up_to: "	取決於	",
    commission_free: "	佣金免費	",
    deposit_bonus: "	存款獎金	",
    deposit_bonus_35:
      "	通過{{value1}}％的存款獎金來增強您的交易資本。存入您的選擇金額，並為您的帳戶獲得最大{{value2}}美元的存款獎金。充滿信心並抓住金融市場的機會。	",
    deposit_bonus_25:
      "	通過{{value1}}％的存款獎金來增強您的交易資本。存入您的選擇金額，並為您的帳戶獲得最大{{value2}}美元的存款獎金。不要錯過這個絕佳的機會來增強您的交易資本。	",
    bonus_condition: "	獎勵條件	",
    bonus_condition_detail:
      "	您將收到與存入額度成正比的獎金金額，並根據指定的最高限制。	",
    bonus_notice_error: "您尚未通過所有撤回獎金的條件",
    withdraw_option: "	提取選項	",
    invite_more: "	邀請更多，得到更多。	",
    invite: "	邀請	",
    invite_person: "	邀請更多/人	",
    maximum_withdraw: "	最大提款	",
    invie_link: "	邀請鏈接	",
    reminder: "	提醒	",
    remider_detail:
      "	被邀請的必須成功驗證其身份和銀行帳戶，並首先獲得獎金。因此，人們認為邀請朋友是成功的。	",
    withdraw_conditions: "	戒斷條件	",
    complete_all: "	完成所有3個步驟撤離	",
    not_yet: "	還沒開始	",
    complete: "	完全的	",
    processing: "	加工	",
    remider_condition_1: "	必須在提取之前關閉所有職位。	",
    remider_condition_2: "	對於這些條件，只有持續時間超過5分鐘的位置才能使用。	",
    remider_condition_3: "	提款成功後，信貸帳戶將永久關閉。	",
    conditions: "	狀況	",
    close: "	關閉	",
    banner_partner_title: "	成為IUX合作夥伴	",
    banner_partner_detail:
      "	立即加入所有合作夥伴計劃，最多可獲得10,000美元和50％的終身委員會。	",
    banner_join_partner: "加入IUX合作夥伴",
    banner_bonus_detail: `通過IUX市場開設一個實時交易帳戶，並獲得最高30美元的免費獎金`,
    banner_default_detail: `直接从移动设备上交易世界领先平台上的CFD。`,
    banner_trade_detail: `在用户友好，自定义构建中访问行业的卓越图表功能。`,
    banner_trade_button: `开放交易平台`,
    congrat: "	恭喜	",
    congrat_detail: "	您已經成功創建了交易帳戶	",
    congrat_demo_detail: "您已經成功創建了演示帳戶",
    account_number: "	帳號	",
    bonus_create_success:
      "	我們請要求您將交易密碼RIFT保存，因為由於安全目的，無法將其發送到您的電子郵件中。	",
    bonus_button: "获得奖励",
    bonus_header: "促销",
    bonus_content:
      "第开户推广金钱促销或其他促销活动，将在未来发生，也不会在真金白银的形式被撤回。",
    bonus_box_1: {
      text_1: `首次存款$ 10`,
      text_2: `存入资金后收到30 USD美元`,
      text_3: `所有的利润可以无条件收回。`,
      text_4: `对于新客户`,
      text_5: `不能撤回奖励基金`,
    },
    bonus_box_2: {
      text_1: `首次存款$ 20`,
      text_2: `存入资金后收到$ 50美元`,
      text_3: `所有的利润可以无条件收回。`,
      text_4: `对于新客户`,
      text_5: `不能撤回奖励基金`,
    },
    banner: {
      mt5: `A powerful platform for CFDs and Exchange markets`,
    },
    btn: {
      mt5: `Open MT5 Account`,
      mt4: `Open MT4 Account`,
    },
    compare: {
      des_1: `IUX 創建了我們自己的交易平台，以確保我們的客戶可以隨時隨地進行交易。`,
      des_2: `MetaTrader 4 和 MetaTrader 5 都是強大且流行的平台，允許交易者進入金融市場，而 IUX 保證帶來各自的最佳功能，以提供完美的交易環境。`,
      des_3: `我們建議在 IUX MT5 上進行交易，以實現順暢無故障的交易。`,
      titile_2: `功能对比`,
      sub_titile_2: `在两个平台上`,
      mt5: {
        text_1: `更少的交易条件`,
        text_2: `更便宜的交易费用`,
        text_3: `准确的买卖价格`,
        text_4: `下限位`,
        text_5: `即时交易（无重新报价）`,
        text_6: `更多交易产品`,
        text_7: `快速稳定的连接`,
        text_8: `更多交易功能`,
        text_9: `更多账户类型`,
      },
      mt4: {
        text_1: `更多交易条件`,
        text_2: `昂贵的交易费用`,
        text_3: `买卖价格不稳定`,
        text_4: `更高的止损水平`,
        text_5: `经常重新报价`,
        text_6: `交易产品少`,
        text_7: `连接不稳定`,
        text_8: `交易功能少`,
        text_9: `有限的账户类型`,
      },
    },
  },
  vi: {
    bonus_update: "Cập nhật",
    bonus_update_warning: "Vui lòng đợi một lát trước khi cập nhật lại.",
    received: "đã nhận",
    no_deposit_bonus: "	Không có tiền thưởng tiền gửi	",
    bonus_30_detail: `Tiền thưởng tức thì 30 USD sẽ được tự động ghi có vào tài khoản của bạn
                  Khi mở một tài khoản chào mừng. Bạn có thể giao dịch với phần thưởng này,
                  Và bất kỳ lợi nhuận nào bạn kiếm được có thể rút được bởi các điều kiện. Xoay
                  Quỹ miễn phí của bạn vào lợi nhuận thực sự!`,
    starts: "	bắt đầu	",
    up_to: "	lên đến	",
    commission_free: "	Hoa hồng miễn phí	",
    deposit_bonus: "	Tiền thưởng	",
    deposit_bonus_35:
      "	Tăng vốn giao dịch của bạn với tiền thưởng tiền gửi {{value1}}%. Gửi một số tiền bạn chọn và nhận tối đa {{value2}} USD tiền thưởng tiền gửi vào tài khoản của bạn. Thương mại với sự tự tin và nắm bắt cơ hội trên thị trường tài chính.	",
    deposit_bonus_25:
      "	Tăng vốn giao dịch của bạn với tiền thưởng tiền gửi {{value1}}%. Gửi một số tiền bạn chọn và nhận tối đa {{value2}} USD tiền thưởng tiền gửi vào tài khoản của bạn. Đừng bỏ lỡ cơ hội tuyệt vời này để tăng cường vốn giao dịch của bạn.	",
    bonus_condition: "	Điều kiện thưởng	",
    bonus_condition_detail:
      "	Bạn sẽ nhận được một số tiền thưởng tỷ lệ thuận với tổng số tiền gửi, với giới hạn tối đa theo quy định.	",
    bonus_notice_error:
      "Bạn đã không vượt qua tất cả các điều kiện để rút tiền thưởng",
    withdraw_option: "	Tùy chọn rút tiền	",
    invite_more: "	Mời nhiều hơn, nhận được nhiều hơn.	",
    invite: "	mời	",
    invite_person: "	Mời nhiều hơn/ người	",
    maximum_withdraw: "	Rút tiền tối đa	",
    invie_link: "	Liên kết lời mời	",
    reminder: "	Lời nhắc nhở	",
    remider_detail:
      "	Người được mời phải xác minh thành công danh tính và tài khoản ngân hàng của họ và nhận tiền thưởng trước. Do đó, nó sẽ được coi là mời bạn bè thành công.	",
    withdraw_conditions: "	Điều kiện rút tiền	",
    complete_all: "	Hoàn thành tất cả 3 bước để rút tiền	",
    not_yet: "	Vẫn chưa bắt đầu	",
    complete: "	Hoàn thành	",
    processing: "	Xử lý	",
    remider_condition_1:
      "	Tất cả các vị trí phải được đóng cửa trước khi rút tiền có thể được thực hiện.	",
    remider_condition_2:
      "	Chỉ các vị trí kéo dài hơn 5 phút sẽ được xem xét cho các điều kiện này.	",
    remider_condition_3:
      "	Sau khi rút tiền thành công, tài khoản tín dụng sẽ được đóng vĩnh viễn.	",
    conditions: "	Điều kiện	",
    close: "	Đóng	",
    banner_partner_title: "	Trở thành đối tác IUX	",
    banner_partner_detail:
      "	Tham gia cùng một lúc cho tất cả các chương trình đối tác, kiếm được tới 10,000 đô la và 50% hoa hồng trọn đời.	",
    banner_join_partner: "Trở thành Đối tác",
    banner_bonus_detail: `Mở tài khoản giao dịch trực tiếp với thị trường IUX và nhận được tiền thưởng tới 30 USD miễn phí`,
    banner_default_detail: `CFD thương mại trên các nền tảng hàng đầu thế giới trực tiếp từ thiết bị di động.`,
    banner_trade_detail: `Truy cập chức năng biểu đồ xuất sắc của ngành công nghiệp trên cơ sở thân thiện với người dùng, được xây dựng tùy chỉnh.`,
    banner_trade_button: `Mở nền tảng giao dịch`,
    congrat: "	Chúc mừng	",
    congrat_detail: "	Bạn đã tạo thành công tài khoản giao dịch	",
    congrat_demo_detail: "Bạn đã tạo thành công tài khoản demo",
    account_number: "	Số tài khoản	",
    bonus_create_success:
      "	Chúng tôi vui lòng yêu cầu bạn lưu Rift Mật khẩu giao dịch của mình vì nó không thể được gửi đến email của bạn do mục đích bảo mật.	",
    bonus_button: "Nhận tiền thưởng",
    bonus_header: "Chương trình khuyến mãi",
    bonus_content:
      "Khuyến mãi mở tài khoản đầu tiên, Khuyến mãi tiền hoặc khuyến mãi khác mà sẽ gây ra trong tương lai, không thể rút được bằng tiền thật.",
    bonus_box_1: {
      text_1: `Nạp tiền lần đầu tiên 10$`,
      text_2: `Nhận tín dụng 30$ sau khi nạp tiền`,
      text_3: `Tất cả lợi nhuận có thể rút được theo điều kiện và chính sách.`,
      text_4: `Đối với khách hàng mới`,
      text_5: `Không thể rút tiền thưởng`,
    },
    bonus_box_2: {
      text_1: `Nạp tiền lần đầu tiên 20 $`,
      text_2: `Nhận tín dụng 50$ sau khi nạp tiền`,
      text_3: `Tất cả lợi nhuận có thể rút được theo điều kiện và chính sách.`,
      text_4: `Đối với khách hàng mới`,
      text_5: `Không thể rút tiền thưởng`,
    },
    banner: {
      mt5: `Nền tảng mạnh mẽ đối với thị trường CFDs và Exchange`,
    },
    btn: {
      mt5: `Mở tài khoản MT5`,
      mt4: `Mở tài khoản  MT4`,
    },
    compare: {
      des_1: `IUX đã thiết kế nền tảng của họ để đảm bảo rằng các nhà giao dịch đều có thể giao dịch theo định dạng ưa thích của họ trong những địa điểm yêu thích của họ và trên thiết bị mà họ đã chọn.`,
      des_2: `Cả MetaTrader 4 và MetaTrader 5 nền tảng giao dịch phổ biến và mạnh mẽ, cho phép các nhà giao dịch tiếp cận thị trường tài chính và IUX được kiểm tra rằng đã tận dụng các tính năng tốt nhất của mỗi loại để cung cấp môi trường giao dịch hoàn hảo.`,
      des_3: `Chúng tôi khuyên bạn nên sử dụng IUX MT5 để giao dịch suôn sẻ và không vấp phải mọi giao dịch.`,
      titile_2: `Sự so sánh tài sản`,
      sub_titile_2: `Hiện thị sự khác biệt.`,
      mt5: {
        text_1: `Điều kiện giao dịch ít hơn.`,
        text_2: `Phí giao dịch thấp hơn.`,
        text_3: `Giá giao dịch giá mở-đóng giống với biểu đồ.`,
        text_4: `Stop Level thấp hơn.`,
        text_5: `Giao dịch ngay lập tức.`,
        text_6: `Hàng hóa nhiều hơn.`,
        text_7: `Kết nối nhanh chóng.`,
        text_8: `Chức năng giao dịch nhiều hơn.`,
        text_9: `Loại tài khoản nhiều hơn.`,
      },
      mt4: {
        text_1: `Điều kiện giao dịch nhiều hơn.`,
        text_2: `Phí giao dịch cao hơn.`,
        text_3: `Giá giao dịch giá mở-đóng không ổn định.`,
        text_4: `Stop Level cao hơn.`,
        text_5: `Việc giao hàng chậm trễ`,
        text_6: `Ít hàng hóa`,
        text_7: `Kết nối chậm và không ổn định`,
        text_8: `Ít chức năng giao dịch.`,
        text_9: `Hạn chế loại tài khoản.`,
      },
    },
  },
  ko: {
    bonus_update: "Update",
    bonus_update_warning: "Please wait a moment before updating again.",
    received: "받았다",
    no_deposit_bonus: "	예금 보너스가 없습니다	",
    bonus_30_detail: `     즉시 30 USD 보너스는 귀하의 계정에 자동으로 적용됩니다.
                  환영 계정을 열 때. 이 보너스로 거래 할 수 있습니다.
                  그리고 당신이 얻은 모든 이익은 조건에 따라 철회 할 수 있습니다. 회전하다
                  당신의 무료 펀드 진정한 이익!`,
    starts: "	시작	",
    up_to: "	까지	",
    commission_free: "	커미션 무료	",
    deposit_bonus: "	예금 보너스	",
    deposit_bonus_35:
      "	{{value1}}%의 예금 보너스로 거래 자본을 늘리십시오. 선택한 금액을 입금하고 귀하의 계좌에 최대 {{value2}} USD의 예금 보너스를 받으십시오. 신뢰로 거래하고 금융 시장에서 기회를 포착하십시오.	",
    deposit_bonus_25:
      "	{{value1}}%의 예금 보너스로 거래 자본을 늘리십시오. 선택한 금액을 입금하고 귀하의 계좌에 최대 {{value2}} USD의 예금 보너스를 받으십시오. 거래 자본을 과급 할 수있는이 환상적인 기회를 놓치지 마십시오.	",
    bonus_condition: "	보너스 조건	",
    bonus_condition_detail:
      "	예금 금액에 비례하는 보너스 금액을 받게되며, 지정된 최대 한도입니다.	",
    bonus_notice_error:
      "당신은 보너스를 철회하기위한 모든 조건을 통과하지 못했습니다.",
    withdraw_option: "	옵션을 철회하십시오	",
    invite_more: "	더 많이 초대하고 더 많은 것을 얻으십시오.	",
    invite: "	초대하다	",
    invite_person: "	더 많은/ 사람을 초대하십시오	",
    maximum_withdraw: "	최대 철수	",
    invie_link: "	초대장 링크	",
    reminder: "	알림	",
    remider_detail:
      "	초대 된 사람은 신원 및 은행 계좌를 성공적으로 확인하고 보너스를 먼저 받아야합니다. 그러므로 초대하는 친구가 성공한 것으로 간주 될 것입니다.	",
    withdraw_conditions: "	철수 조건	",
    complete_all: "	철회를 위해 3 단계를 모두 완료하십시오	",
    not_yet: "	아직 시작되지 않았습니다	",
    complete: "	완벽한	",
    processing: "	처리	",
    remider_condition_1: "	철수를하기 전에 모든 직책을 닫아야합니다.	",
    remider_condition_2:
      "	이러한 조건에서는 5 분 이상 지속되는 위치 만 고려됩니다.	",
    remider_condition_3: "	인출이 성공하면 신용 계정이 영구적으로 마감됩니다.	",
    conditions: "	정황	",
    close: "	닫다	",
    banner_partner_title: "	IUX 파트너가됩니다	",
    banner_partner_detail:
      "	모든 파트너 프로그램에 대해 한 번에 가입하고 최대 $ 10,000 및 50%의 평생 수수료를받습니다.	",
    banner_join_partner: "IUX 파트너에 가입하십시오",
    banner_bonus_detail: `IUX 시장과 함께 라이브 거래 계좌를 개설하고 최대 30 USD 보너스 무료`,
    banner_default_detail: `모바일에서 직접 세계 최고의 플랫폼에서 CFD를 거래하십시오.`,
    banner_trade_detail: `사용자 친화적 인 사용자 정의 제작에서 업계의 우수 차트 기능에 액세스하십시오.`,
    banner_trade_button: `개방형 거래 플랫폼`,
    congrat: "	축하해요	",
    congrat_detail: "	당신은 거래 계정을 성공적으로 만들었습니다	",
    congrat_demo_detail: "데모 계정을 성공적으로 만들었습니다",
    account_number: "	계좌 번호	",
    bonus_create_success:
      "	보안 목적으로 인해 이메일로 전송 할 수 없으므로 거래 비밀번호를 저장하도록 친절하게 요청합니다.	",
    bonus_button: "보너스를 받기",
    bonus_header: "프로모션",
    bonus_content:
      "미래에 발생합니다 첫 번째 계좌 개설 프로모션 돈 프로모션 또는 다른 프로모션이는 실제 현금의 형태로 철회 할 수 없습니다.",
    bonus_box_1: {
      text_1: `먼저 보증금 10 $`,
      text_2: `자금을 입금 한 후 30 USD 크레딧을받을 수`,
      text_3: `모든 이익은 조건없이 철회 할 수 있습니다.`,
      text_4: `신규 고객의 경우`,
      text_5: `보너스 자금을 인출 할 수 없습니다`,
    },
    bonus_box_2: {
      text_1: `먼저 보증금 20 $`,
      text_2: `자금을 입금 한 후 $ 50 크레딧을받을 수`,
      text_3: `모든 이익은 조건없이 철회 할 수 있습니다.`,
      text_4: `신규 고객의 경우`,
      text_5: `보너스 자금을 인출 할 수 없습니다`,
    },
    banner: {
      mt5: `A powerful platform for CFDs and Exchange markets`,
    },
    btn: {
      mt5: `Open MT5 Account`,
      mt4: `Open MT4 Account`,
    },
    compare: {
      des_1: `IUX has created our own trading platform to make sure our clients can trade wherever and whenever they want.`,
      des_2: `Both  MetaTrader 5 are powerful and popular platforms which allow traders to access financial markets, and IUX guaranteed to bring  the best features of each to provide the perfect trading environment.`,
      des_3: `We recommend trading on IUX MT5 for smooth and trouble-free trading.`,
      titile_2: `Features comparison on both platforms`,
      sub_titile_2: `Show the difference`,
      mt5: {
        text_1: `Lesss trading conditions`,
        text_2: `Cheaper trading fees`,
        text_3: `Accurate Bid-Ask price`,
        text_4: `Lower Stop Level`,
        text_5: `Immediate trading (No requote)`,
        text_6: `More trading products`,
        text_7: `Fast and stable connection`,
        text_8: `More trading functions`,
        text_9: `More account type`,
      },
      mt4: {
        text_1: `More trading conditions`,
        text_2: `Expensive trading fees`,
        text_3: `Unstable Bid-Ask price`,
        text_4: `Higher Stop Level`,
        text_5: `Frequently requote`,
        text_6: `Less trading products`,
        text_7: `Unstable connection`,
        text_8: `Less trading functions`,
        text_9: `Limited account types`,
      },
    },
  },
  fil: {
    bonus_update: "Update",
    bonus_update_warning:
      "Mangyaring maghintay ng ilang sandali bago mag-update muli.",
    received: "natanggap",
    no_deposit_bonus: "	Walang deposit bonus	",
    bonus_30_detail: ` Ang instant na 30 USD bonus ay awtomatikong mai -kredito sa iyong account
                  Kapag nagbubukas ng isang maligayang account. Maaari kang makipagkalakalan sa bonus na ito,
                  At ang anumang kita na kikitain mo ay maaaring bawiin ng mga kondisyon. Lumiko
                  Ang iyong libreng pondo sa totoong kita!`,
    starts: "	nagsisimula	",
    up_to: "	hanggang	",
    commission_free: "	Libre ang komisyon	",
    deposit_bonus: "	Deposit Bonus	",
    deposit_bonus_35:
      "	Palakasin ang iyong kapital sa pangangalakal na may {{value1}}% na bonus ng deposito. Magdeposito ng isang halaga ng iyong pinili, at makakuha ng maximum na {{value2}} USD ng deposit bonus sa iyong account. Makipagkalakalan nang may kumpiyansa at sakupin ang mga pagkakataon sa mga pamilihan sa pananalapi.	",
    deposit_bonus_25:
      "	Palakasin ang iyong kapital sa pangangalakal na may {{value1}}% na bonus ng deposito. Magdeposito ng isang halaga ng iyong napili, at makakuha ng maximum na {{value2}} USD ng deposit bonus sa iyong account. Huwag palampasin ang kamangha -manghang oportunidad na ito upang ma -supercharge ang iyong kapital sa pangangalakal.	",
    bonus_condition: "	Kondisyon ng bonus	",
    bonus_condition_detail:
      "	Makakatanggap ka ng isang halaga ng bonus na proporsyonal sa na -deposito na kabuuan, na may isang maximum na limitasyon tulad ng tinukoy.	",
    bonus_notice_error:
      "Hindi mo naipasa ang lahat ng mga kondisyon para sa pag -alis ng bonus",
    withdraw_option: "	Bawiin ang mga pagpipilian	",
    invite_more: "	Anyayahan pa, kumuha ng higit pa.	",
    invite: "	mag-anyaya	",
    invite_person: "	Anyayahan pa/ tao	",
    maximum_withdraw: "	Maximum na pag -atras	",
    invie_link: "	Link ng paanyaya	",
    reminder: "	Paalala	",
    remider_detail:
      "	Ang inanyayahan ay dapat matagumpay na mapatunayan ang kanilang pagkakakilanlan at bank account at makuha muna ang bonus. Samakatuwid, isasaalang -alang na ang pag -anyaya sa mga kaibigan ay matagumpay.	",
    withdraw_conditions: "	Mga kondisyon ng pag -atras	",
    complete_all: "	Kumpletuhin ang lahat ng 3 mga hakbang sa pag -atras	",
    not_yet: "	Hindi pa nagsisimula	",
    complete: "	Kumpleto	",
    processing: "	Pagproseso	",
    remider_condition_1:
      "	Ang lahat ng mga posisyon ay dapat isara bago magawa ang pag -alis.	",
    remider_condition_2:
      "	Ang mga posisyon lamang na tumatagal ng mas mahaba kaysa sa 5 minuto ay isasaalang -alang para sa mga kundisyong ito.	",
    remider_condition_3:
      "	Kapag ang pag -alis ay matagumpay, ang credit account ay sarado na sarado.	",
    conditions: "	Mga kondisyon	",
    close: "	Malapit	",
    banner_partner_title: "	Naging kasosyo sa IUX	",
    banner_partner_detail:
      "	Sumali nang sabaysabay para sa lahat ng mga programa ng kasosyo, kumita ng hanggang sa $ 10,000 at 50% na komisyon sa buhay.	",
    banner_join_partner: "maging partner",
    banner_bonus_detail: `Magbukas ng isang live na account sa pangangalakal na may mga IUX at makakuha ng hanggang sa 30 USD bonus libre`,
    banner_default_detail: `Ang mga trade CFD sa nangungunang platform ng mundo nang direkta mula sa mobile.`,
    banner_trade_detail: `I-access ang pag-andar ng kahusayan ng industriya ng kahusayan sa user-friendly, pasadyang itinayo.`,
    banner_trade_button: `Buksan ang platform ng kalakalan`,
    congrat: "	Binabati kita	",
    congrat_detail: "	Matagumpay mong nilikha ang Trading Account	",
    congrat_demo_detail: "Matagumpay mong nilikha ang demo account",
    account_number: "	Numero ng account	",
    bonus_create_success:
      "	Mabait naming hiniling na i -save mo ang iyong password sa pangangalakal na lumayo dahil hindi ito maipadala sa iyong email dahil sa mga layunin ng seguridad.	",
    bonus_button: "Kumuha ng Bonus",
    bonus_header: "Mga Pag-promote",
    bonus_content:
      "Ang unang account opening promotion Money promotion O iba pang mga pag-promote na ay magaganap sa hinaharap Ito ay hindi maaaring ma-withdraw sa anyo ng mga tunay na pera.",
    bonus_box_1: {
      text_1: `Unang deposito na 10 $`,
      text_2: `Makatanggap ng 30 USD credit matapos pagdeposito ng mga pondo`,
      text_3: `Ang lahat ng mga kita ay maaaring withdraw nang walang kondisyon.`,
      text_4: `Para sa mga bagong customer`,
      text_5: `Hindi withdraw ng mga pondo bonus`,
    },
    bonus_box_2: {
      text_1: `Unang deposito na 20 $`,
      text_2: `Makatanggap ng $ 50 credit matapos pagdeposito ng mga pondo`,
      text_3: `Ang lahat ng mga kita ay maaaring withdraw nang walang kondisyon.`,
      text_4: `Para sa mga bagong customer`,
      text_5: `Hindi withdraw ng mga pondo bonus`,
    },

    banner: {
      mt5: `A powerful platform for CFDs and Exchange markets`,
    },
    btn: {
      mt5: `Open MT5 Account`,
      mt4: `Open MT4 Account`,
    },
    compare: {
      des_1: `IUX has created our own trading platform to make sure our clients can trade wherever and whenever they want.`,
      des_2: `Both  MetaTrader 5 are powerful and popular platforms which allow traders to access financial markets, and IUX guaranteed to bring  the best features of each to provide the perfect trading environment.`,
      des_3: `We recommend trading on IUX MT5 for smooth and trouble-free trading.`,
      titile_2: `Features comparison on both platforms`,
      sub_titile_2: `Show the difference`,
      mt5: {
        text_1: `Lesss trading conditions`,
        text_2: `Cheaper trading fees`,
        text_3: `Accurate Bid-Ask price`,
        text_4: `Lower Stop Level`,
        text_5: `Immediate trading (No requote)`,
        text_6: `More trading products`,
        text_7: `Fast and stable connection`,
        text_8: `More trading functions`,
        text_9: `More account type`,
      },
      mt4: {
        text_1: `More trading conditions`,
        text_2: `Expensive trading fees`,
        text_3: `Unstable Bid-Ask price`,
        text_4: `Higher Stop Level`,
        text_5: `Frequently requote`,
        text_6: `Less trading products`,
        text_7: `Unstable connection`,
        text_8: `Less trading functions`,
        text_9: `Limited account types`,
      },
    },
  },
  ja: {
    bonus_update: "アップデート",
    bonus_update_warning: "再度更新するまでしばらくお待ちください。",
    received: "受け取る",
    no_deposit_bonus: "デポジットボーナスはありません",
    bonus_30_detail:
      "ウェルカムアカウントを開設すると、インスタント30ドルのボーナスがアカウントに自動的にクレジットされます。 このボーナスで取引することができ、獲得した利益は条件によって引き出します。 フリーファンドを実際の利益に変えてください！",
    starts: "開始",
    up_to: "まで",
    commission_free: "委員会無料",
    deposit_bonus: "デポジットボーナス",
    deposit_bonus_35:
      "{{value1}}％のデポジットボーナスで貿易資金を後押しします。 お好みの金額をデポジットし、アカウントに最大{{value2}}米ドルのデポジットボーナスを取得します。 自信を持って取引し、金融市場で機会をつかむ。",
    deposit_bonus_25:
      "{{value1}}％のデポジットボーナスで取引資本を後押しします。 お好みの金額を入金し、アカウントに最大{{value2}}米ドルのデポジットボーナスを取得します。 あなたの取引資本を充電するこの素晴らしい機会をお見逃しなく。",
    bonus_condition: "ボーナス条件",
    bonus_condition_detail:
      "指定された最大制限で、堆積額に比例するボーナス額を受け取ります。",
    bonus_notice_error:
      "あなたはボヌを撤回するためのすべての条件を通過していません",
    withdraw_option: "オプションを引き出します",
    invite_more: "もっと招待して、もっと入手してください。",
    invite: "招待する",
    invite_person: "さらに招待する \n /人",
    maximum_withdraw: "最大引き出し",
    invie_link: "招待状リンク",
    reminder: "リマインダー",
    remider_detail:
      "招待された人は、自分の身元と銀行口座を正常に検証し、最初にボーナスを取得する必要があります。 したがって、友人を招待することは成功すると考えられます。",
    withdraw_conditions: "離脱条件",
    complete_all: "撤退のために3つのステップすべてを完了します",
    not_yet: "まだ始まっていない",
    complete: "完了",
    processing: "処理",
    remider_condition_1: "撤退する前に、すべての位置を閉じる必要があります。",
    remider_condition_2:
      "これらの条件では、5分以上続く位置のみが考慮されます。",
    remider_condition_3:
      "撤退が成功すると、クレジットアカウントは永久に閉鎖されます。",
    conditions: "条件",
    close: "近い",
    banner_partner_title: "IUXパートナーになります",
    banner_partner_detail:
      "すべてのパートナープログラムに一度に参加し、最大10,000ドルと50％の生涯委員会を獲得します。",
    banner_join_partner: "IUXパートナー",
    banner_bonus_detail:
      "IUX市場でライブトレーディングアカウントを開設し、最大30ドルのボーナス無料を取得します",
    banner_default_detail: `モバイルから直接世界をリードするプラットフォームでCFDを取引します。`,
    banner_trade_detail: `ユーザーフレンドリーなカスタムビルドで業界の優秀なチャート機能にアクセスします。`,
    banner_trade_button: `オープントレーディングプラットフォーム`,
    congrat: "おめでとう！",
    congrat_detail: "トレーディングアカウントを正常に作成しました",
    congrat_demo_detail: "デモアカウントを正常に作成しました",
    account_number: "口座番号",
    bonus_create_success:
      "セキュリティのためにメールに送信できないため、取引パスワードのリフトを保存することをお勧めします。",
    bonus_button: "ボヌを入手してください",
    bonus_header: "プロモーション",
    bonus_content:
      "最初のアカウントプロモーション、マネープロモーション、または将来になるその他のプロモーションは、実際のお金の形で撤退することはできません。",
    bonus_box_1: {
      text_1: "最初のデポジット10 $",
      text_2: "デポジット後に30ドルのクレジットを取得します。",
      text_3: "すべての利益は、ポリシーに従って条件で撤回することができます",
      text_4: "新規顧客向け",
      text_5: "ボーナスを撤回することはできません",
    },
    bonus_box_2: {
      text_1: "最初に20ドルを入金します",
      text_2: "資金を預けた後、50ドルのクレジットを受け取ります",
      text_3: "すべての利益は、条件なしで撤回することができます。",
      text_4: "新規顧客向け",
      text_5: "ボーナスファンドを撤回することはできません",
    },
    banner: {
      mt5: "CFDおよび交換市場向けの強力なプラットフォーム",
    },
    btn: {
      mt5: "MT5アカウントを開きます",
      mt4: "MT4アカウントを開きます",
    },
    compare: {
      des_1:
        "IUXは、クライアントがいつでもいつでもいつでも取引できるようにする独自の取引プラットフォームを作成しました。",
      des_2:
        "Metatrader 5の両方は、トレーダーが金融市場にアクセスできるようにする強力で人気のあるプラットフォームであり、IUX市場はそれぞれの最高の機能を提供して完璧な取引環境を提供することを保証しています。",
      des_3:
        "スムーズでトラブルのない取引のために、IUX MT5での取引をお勧めします。",
      titile_2: "両方のプラットフォームでの比較を特徴とします",
      sub_titile_2: "違いを示します",
      mt5: {
        text_1: "より少ない取引条件",
        text_2: "より安い取引料",
        text_3: "正確な入札価格",
        text_4: "低停止レベル",
        text_5: "即時取引（recoteなし）",
        text_6: "より多くの取引製品",
        text_7: "高速で安定した接続",
        text_8: "より多くの取引機能",
        text_9: "より多くのアカウントタイプ",
      },
      mt4: {
        text_1: "より多くの取引条件",
        text_2: "高価な取引料",
        text_3: "不安定な入札価格",
        text_4: "より高い停止レベル",
        text_5: "頻繁にリクエートします",
        text_6: "より少ない取引製品",
        text_7: "不安定な接続",
        text_8: "より少ない取引機能",
        text_9: "限られたアカウントタイプ",
      },
    },
  },

  es: {
    bonus_update: "Actualizar",
    bonus_update_warning:
      "Por favor, espere un momento antes de actualizar nuevamente.",
    received: "Recibido",
    no_deposit_bonus: "Bono sin depósito",
    bonus_30_detail:
      "El bono instantáneo de 30 USD se acreditará automáticamente en su cuenta al abrir una cuenta de bienvenida. Puede operar con este bono, y cualquier ganancia que obtenga se puede retirar bajo ciertas condiciones. ¡Convierta su fondo gratuito en ganancias reales!",
    starts: "comienza",
    up_to: "hasta",
    commission_free: "Sin comisión",
    deposit_bonus: "Bono de depósito",
    deposit_bonus_35:
      "Aumente su capital comercial con un bono de depósito del {{value1}}%. Deposite la cantidad que desee y obtenga un máximo de {{value2}} USD de bono de depósito en su cuenta. Opere con confianza y aproveche las oportunidades en los mercados financieros.",
    deposit_bonus_25:
      "Aumente su capital comercial con un bono de depósito del {{value1}}%. Deposite la cantidad que desee y obtenga un máximo de {{value2}} USD de bono de depósito en su cuenta. No se pierda esta fantástica oportunidad de impulsar su capital comercial.",
    bonus_condition: "Condiciones del bono",
    bonus_condition_detail:
      "Recibirá una cantidad de bono proporcional a la suma depositada, con un límite máximo especificado.",
    bonus_notice_error:
      "No ha cumplido todas las condiciones para retirar el bono",
    withdraw_option: "Opciones de retiro",
    invite_more: "Invite más, gane más.",
    invite: "invitar",
    invite_person: "Invite más\n/Persona",
    maximum_withdraw: "Retiro\nmáximo",
    invie_link: "Enlace de invitación",
    reminder: "Recordatorio",
    remider_detail:
      "El invitado debe verificar con éxito su identidad y cuenta bancaria y obtener el bono primero. Por lo tanto, se considerará que la invitación de amigos es exitosa.",
    withdraw_conditions: "Condiciones de retiro",
    complete_all: "Complete los 3 pasos para el retiro",
    not_yet: "Aún no iniciado",
    complete: "Completo",
    processing: "Procesando",
    remider_condition_1:
      "Todas las posiciones deben estar cerradas antes de poder realizar el retiro.",
    remider_condition_2:
      "Solo se considerarán para estas condiciones las posiciones que duren más de 5 minutos.",
    remider_condition_3:
      "Una vez que el retiro sea exitoso, la cuenta de crédito se cerrará permanentemente.",
    conditions: "Condiciones",
    close: "Cerrar",
    banner_partner_title: "Conviértete en socio de IUX",
    banner_partner_detail:
      "Únase a todos los programas de socios de una vez, gane hasta $10,000 y 50% de comisión de por vida.",
    banner_join_partner: "Socio de IUX",
    banner_bonus_detail:
      "Abra una cuenta de trading en vivo con IUX y obtenga hasta 30 USD de bono gratuito",
    banner_default_detail:
      "Opere CFDs en las plataformas líderes del mundo directamente desde el móvil.",
    banner_trade_detail:
      "Acceda a la excelente funcionalidad de gráficos de la industria en la plataforma de trading personalizada y fácil de usar.",
    banner_trade_button: "Abrir plataforma de trading",
    congrat: "¡Felicidades!",
    congrat_detail: "Ha creado exitosamente una cuenta de trading",
    congrat_demo_detail: "Ha creado exitosamente una cuenta demo",
    account_number: "Número de cuenta",
    bonus_create_success:
      "Le solicitamos amablemente que guarde su contraseña de trading de inmediato, ya que no se puede enviar a su correo electrónico por motivos de seguridad.",
    bonus_button: "Obtener bono",
    bonus_header: "PROMOCIONES",
    bonus_content:
      "La primera promoción de cuenta, promoción de dinero o cualquier otra promoción que se lleve a cabo en el futuro, no se puede retirar en forma de dinero real.",
    bonus_box_1: {
      text_1: "Primer depósito de 10 $",
      text_2: "Obtenga 30 $ de crédito después del depósito.",
      text_3:
        "Todas las ganancias se pueden retirar con condiciones según la política",
      text_4: "Para nuevos clientes",
      text_5: "El bono no se puede retirar",
    },
    bonus_box_2: {
      text_1: "Primer depósito de 20 $",
      text_2: "Reciba un crédito de $50 después de depositar fondos",
      text_3: "Todas las ganancias se pueden retirar sin condiciones.",
      text_4: "Para nuevos clientes",
      text_5: "No se pueden retirar los fondos del bono",
    },
    banner: {
      mt5: "Una plataforma poderosa para mercados de CFDs y Exchange",
    },
    btn: {
      mt5: "Abrir cuenta MT5",
      mt4: "Abrir cuenta MT4",
    },
    compare: {
      des_1:
        "IUX ha creado nuestra propia plataforma de trading para asegurarse de que nuestros clientes puedan operar donde y cuando quieran.",
      des_2:
        "Ambas MetaTrader 5 son plataformas poderosas y populares que permiten a los traders acceder a los mercados financieros, e IUX garantiza traer las mejores características de cada una para proporcionar el entorno de trading perfecto.",
      des_3:
        "Recomendamos operar en IUX MT5 para un trading fluido y sin problemas.",
      titile_2: "Comparación de características en ambas plataformas",
      sub_titile_2: "Mostrar la diferencia",
      mt5: {
        text_1: "Menos condiciones de trading",
        text_2: "Tarifas de trading más baratas",
        text_3: "Precio de oferta y demanda preciso",
        text_4: "Nivel de stop más bajo",
        text_5: "Trading inmediato (sin recotización)",
        text_6: "Más productos de trading",
        text_7: "Conexión rápida y estable",
        text_8: "Más funciones de trading",
        text_9: "Más tipos de cuenta",
      },
      mt4: {
        text_1: "Más condiciones de trading",
        text_2: "Tarifas de trading más caras",
        text_3: "Precio de oferta y demanda inestable",
        text_4: "Nivel de stop más alto",
        text_5: "Recotización frecuente",
        text_6: "Menos productos de trading",
        text_7: "Conexión inestable",
        text_8: "Menos funciones de trading",
        text_9: "Tipos de cuenta limitados",
      },
    },
  },
};
