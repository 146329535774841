import { renderApiDomain } from "../helper/renderApiDomain";
import { get, post, put, puts } from "../helper/request";

export const getProvinceList = async () => {
  return get(`${renderApiDomain()}master/province`);
};
export const getBankList = async () => {
  return get(`${renderApiDomain()}master/bank`);
};
export const getCareerList = async () => {
  return get(`${renderApiDomain()}master/career`);
};
export const getUserValidation = async () => {
  return get(`${renderApiDomain()}user/validation`);
};
export const getUserAttempt = async () => {
  return get(`${renderApiDomain()}ocr/attempt`);
};
export const putUserAttempt = async (data) => {
  return puts(`${renderApiDomain()}ocr/attempt`, data);
};
export const postJumioCreate = async () => {
  return post(`${renderApiDomain()}jumio/create`);
};
export const putUserOtpEnable = async () => {
  return puts(`${renderApiDomain()}user/otp/enable-toggle`);
};

export const getUserCustomToken = async () => {
  return get(`${renderApiDomain()}user/custom-token`);
};
export const postUserOTP = async () => {
  return post(`${renderApiDomain()}user/otp`);
};
export const postUserOTPVerify = async (data) => {
  return post(`${renderApiDomain()}user/otp/verify`, data);
};
