import {
  ADD_ACCOUNT,
  UPDATE_ACCOUNT,
  DELETE_ACCOUNT,
  UPDATE_GETBONUS,
  ARCHIVE_ACCOUNT,
  RESTORE_ACCOUNT,
  SET_MODAL_BONUS,
} from "../types";

export const setModalBonus = (isOpen) => {
  return (dispatch) => {
    dispatch({
      type: SET_MODAL_BONUS,
      payload: isOpen,
    });
  };
};
export const updateLaverage = (accounts) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACCOUNT,
      payload: accounts,
    });
  };
};

export const addAccount = (accounts) => {
  return (dispatch) => {
    dispatch({
      type: ADD_ACCOUNT,
      payload: accounts,
    });
  };
};

export const deleteAccount = ([...accounts], account) => {
  var newValues = accounts.filter((value) => {
    return value.id !== account.id;
  });
  return (dispatch) => {
    dispatch({
      type: DELETE_ACCOUNT,
      payload: newValues,
    });
  };
};

export const archiveAccount = ([...accounts], account) => {
  var newValues = accounts.filter((value) => {
    return value.id !== account.id;
  });
  return (dispatch) => {
    dispatch({
      type: ARCHIVE_ACCOUNT,
      payload: newValues,
    });
  };
};

export const restoreAccount = ([...accounts], account) => {
  var newValues = accounts.filter((value) => {
    return value.id !== account.id;
  });
  return (dispatch) => {
    dispatch({
      type: RESTORE_ACCOUNT,
      payload: newValues,
    });
  };
};

export const updateAccount = (accounts, account) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACCOUNT,
      payload: account,
    });
  };
};

export const updateGetBonus = (accounts, isGetBonus) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACCOUNT,
      payload: accounts,
    });
    dispatch({
      type: UPDATE_GETBONUS,
      payload: isGetBonus,
    });
  };
};
