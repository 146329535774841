import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import Connect from "./Connect";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../firebase";
import { getUser } from "../actions";
import RedirectPage from "../redirect";
import { useLocation } from "react-router-dom";
const Guest = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const isInitializeLoading = useSelector(
    (state) => state.auth.isInitializeLoading
  );
  const ispathname = useLocation;
  const isuser = useSelector((state) => state.user);

  let urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user?.emailVerified) {
        // console.log(user);
        setUser(user);
        // dispatch(getUser());
      }
      setIsLoading(false);
    });
    return () => unsubscribe();
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoading ? (
          <RedirectPage />
        ) : (
          <>
            {user &&
            urlParams.get("hidenav") !== "hide" &&
            isuser?.isAuth &&
            !urlParams.get("otp") ? (
              <Redirect
                to={{
                  pathname: `/${localStorage.getItem("locale")}/wallet/`,
                }}
              />
            ) : (
              <Component {...props} />
            )}
          </>
        )
      }
    />
  );
};

export { Guest };
