import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Divider, Tooltip } from "antd";
import React, { useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { fCurrencySymbol } from "../../helper/formatNumber";
import moment from "moment";
import { toPng } from "html-to-image";
import { fncToFixed } from "../../helper/toFixedCustom";
const locale = require("react-redux-i18n").I18n;
function PaymentSlip(props) {
  const {
    rate,
    type,
    requestedDate,
    bookbank,
    localamount,
    amount,
    account,
    onClose,
    isPayment,
    paymentSelected = null,
    wallet_address = null,
    payment_name = null,
    cryptoFee,
    cryptoRate,
    currencySelected,
  } = props;
  const profileCountry = useSelector((state) => state.user.profileCountry);
  const user = useSelector((state) => state.user);
  const refPaymentSlip = useRef();
  const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);

  const onSaveImages = useCallback(() => {
    if (refPaymentSlip.current === null) {
      return;
    }

    toPng(refPaymentSlip.current, {
      cacheBust: false,
    })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = `${capitalize(type)}IUX${moment(requestedDate).format(
          "YYYYMMDD_HHmmss"
        )}.png`;
        link.href = dataUrl;
        link.click();
        // setimagePreviewLight(dataUrl);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refPaymentSlip, type]);
  const renderRateTotal = () => {
    if (props.account?.account_type === "Cent") {
      return ` ${(amount / 100 - parseFloat(cryptoFee) / parseFloat(cryptoRate))
        .toFixed(currencySelected?.fixed)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
    } else {
      return ` ${(amount - parseFloat(cryptoFee) / parseFloat(cryptoRate))
        .toFixed(currencySelected?.fixed)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
    }
  };
  // const renderRateWithdrawTotal = () => {
  //   if (props.account?.account_type === "Cent") {
  //     return ` ${(
  //       (amount / 100) * parseFloat(cryptoRate) -
  //       parseFloat(cryptoFee)
  //     )
  //       .toFixed(currencySelected?.fixed)
  //       .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
  //   } else {
  //     return ` ${(amount * parseFloat(cryptoRate) - parseFloat(cryptoFee))
  //       .toFixed(currencySelected?.fixed)
  //       .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
  //   }
  // };
  const renderRateWithdrawTotal = () => {
    if (account?.account_type === "Cent") {
      let total =
        (amount / 100) * parseFloat(cryptoRate) - parseFloat(cryptoFee);
      return ` ${fncToFixed(total, currencySelected?.fixed)} `;
    } else {
      let total = amount * parseFloat(cryptoRate) - parseFloat(cryptoFee);
      return ` ${fncToFixed(total, currencySelected?.fixed)} `;
    }
  };
  return (
    <div>
      <div className="d-flex justify-content-center">
        <div ref={refPaymentSlip} className="box-payment-slip ">
          <div className="d-flex justify-content-center">
            <img
              src="/assets/images/icons/iux-logo-outline.png"
              alt="iux-logo-outline"
              width={56}
            />
          </div>
          <div className="box-slip-iux">
            <img
              className="demo-bg"
              src="/assets/images/icons/iux-logo-outline.png"
              alt=""
            />
            <div className="d-flex justify-content-center">
              <div className="badge-icons-noti-slip ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <g clip-path="url(#clip0_8300_9815)">
                    <path
                      d="M8 16C3.58862 16 0 12.4114 0 8C0 3.58862 3.58862 0 8 0C12.4114 0 16 3.58862 16 8C16 12.4114 12.4114 16 8 16ZM8 1C4.14001 1 1 4.14001 1 8C1 11.86 4.14001 15 8 15C11.86 15 15 11.86 15 8C15 4.14001 11.86 1 8 1Z"
                      fill="#1CB894"
                    />
                    <path
                      d="M7.25049 10.6665C7.12244 10.6665 6.99451 10.6179 6.89709 10.5199L4.73047 8.35327C4.53516 8.15784 4.53516 7.84119 4.73047 7.64587C4.92578 7.45056 5.24243 7.45056 5.43787 7.64587L7.2511 9.45923L11.2312 5.47925C11.4265 5.28394 11.7432 5.28394 11.9385 5.47925C12.1338 5.67456 12.1338 5.99121 11.9385 6.18652L7.6051 10.5199C7.50647 10.6179 7.37854 10.6665 7.25049 10.6665Z"
                      fill="#1CB894"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_8300_9815">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
            {wallet_address &&
            props?.payment_key !== "gcash_payout" &&
            props?.payment_key !== "limepay_mobile_money_payout" &&
            props?.payment_key !== "maya_payout" ? (
              <div className="mt-2 fs-20 text-center">
                {props.amount_local || renderRateWithdrawTotal()} &nbsp;
                {currencySelected?.currency || props.currency}
              </div>
            ) : (
              <div className="mt-2 fs-20 text-center">
                {account?.account_type !== "Cent" && "$"}
                {fCurrencySymbol(
                  Math.abs(
                    props.affiliate_transfer_type ? props?.credit : amount
                  )
                )}
                &nbsp;
                {account?.account_type === "Cent" && "USC"}
              </div>
            )}
            <div className="fw-300 text-center">
              {type === "withdraw"
                ? locale.t("withdraw_success.title")
                : locale.t("deposit_info.deposit_success")}
            </div>
            <Divider style={{ margin: "16px 0" }} />
            {type === "withdraw" && (
              <>
                {(paymentSelected?.payment_key === "b2binpay" ||
                  props.payment_key === "b2binpay") && (
                  <div>
                    <div className="fw-300">
                      <div className="mt-3 mb-2 text-gray row">
                        <div className="col-5 px-0 px-sm-3">
                          {locale.t("withdraw_info.wallet_address")}
                        </div>
                        <div className="col-7 px-0 px-sm-3">
                          {wallet_address}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {(paymentSelected?.payment_key === "gcash_payout" ||
                  paymentSelected?.payment_key === "maya_payout" ||
                  paymentSelected?.payment_key ===
                    "limepay_mobile_money_payout" ||
                  props?.payment_key === "gcash_payout" ||
                  props?.payment_key === "maya_payout" ||
                  props?.payment_key === "limepay_mobile_money_payout") && (
                  <div className="fw-300">
                    <div className="mt-3 mb-2 text-gray d-flex align-items-center justify-content-between">
                      <div>{paymentSelected?.name || payment_name} Account</div>
                      <div>{user?.profile?.phone_number}</div>
                    </div>
                  </div>
                )}{" "}
                {paymentSelected?.payment_key !== "gcash_payout" &&
                  paymentSelected?.payment_key !== "maya_payout" &&
                  paymentSelected?.payment_key !== "b2binpay" &&
                  paymentSelected?.payment_key !==
                    "limepay_mobile_money_payout" &&
                  props.payment_key !== "b2binpay" &&
                  props?.payment_key !== "gcash_payout" &&
                  props?.payment_key !== "limepay_mobile_money_payout" &&
                  props?.payment_key !== "maya_payout" && (
                    <div className="fw-300">
                      <div className="mt-3 mb-2 text-gray d-flex align-items-center justify-content-between">
                        <div>{locale.t("withdraw_info.account.name")}</div>
                        <div>{bookbank.bank_account_name}</div>
                      </div>
                      <div className="mb-2 text-gray d-flex align-items-center justify-content-between">
                        <div>{locale.t("withdraw_info.account.number")}</div>
                        <div>{bookbank.bank_account_number}</div>
                      </div>
                      <div className="mb-2 text-gray d-flex align-items-center justify-content-between">
                        <div>{locale.t("withdraw_info.account.bank")}</div>
                        <div className="text-right">{bookbank.bank_name}</div>
                      </div>
                    </div>
                  )}
                <Divider style={{ margin: "16px 0" }} dashed />
              </>
            )}
            <div className="mt-3 mb-2 text-gray d-flex align-items-center justify-content-between fw-300">
              <div> {locale.t("withdraw_info.requested_date")}</div>
              <div>{moment(requestedDate).format("YYYY/MM/DD HH:mm")}</div>
            </div>
            <Divider style={{ margin: "16px 0" }} dashed />
            {!props.affiliate_transfer_type && (
              <div className="mb-2 text-gray d-flex align-items-center justify-content-between fw-300">
                <div>
                  {paymentSelected?.payment_key === "gcash_payout" ||
                  paymentSelected?.payment_key === "maya_payout" ||
                  paymentSelected?.payment_key ===
                    "limepay_mobile_money_payout" ||
                  wallet_address
                    ? locale.t("withdraw_info.rate")
                    : locale.t("deposit_info.currency_rate")}
                </div>
                {wallet_address &&
                props?.payment_key !== "gcash_payout" &&
                props?.payment_key !== "limepay_mobile_money_payout" &&
                props?.payment_key !== "maya_payout" ? (
                  <span>
                    {props.usd_rate?.toFixed(2) || cryptoRate}{" "}
                    <span className="fs-10">
                      {props.currency || currencySelected?.currency}USD
                    </span>
                  </span>
                ) : (
                  <span>
                    {fCurrencySymbol(rate.toFixed(2))}&nbsp;
                    {profileCountry?.symbol}
                  </span>
                )}
              </div>
            )}
            {wallet_address &&
              props?.payment_key !== "gcash_payout" &&
              props?.payment_key !== "limepay_mobile_money_payout" &&
              props?.payment_key !== "maya_payout" && (
                <div className="mb-2 text-gray d-flex justify-content-between fw-300">
                  <div>{locale.t("withdraw_info.bockchain_fee")}</div>
                  <span>
                    {props.blockchain_fee || cryptoFee}&nbsp;
                    <span className="fs-10">
                      {props.currency || currencySelected?.currency}
                    </span>
                  </span>
                </div>
              )}{" "}
            {props.affiliate_transfer_type && (
              <div className="mb-2 text-gray d-flex justify-content-between fw-300">
                Transfer to
                <span>
                  {props.account_type}&nbsp;
                  {props.account_number}
                </span>
              </div>
            )}
            {props.affiliate_transfer_type && (
              <div className="mb-2 text-gray d-flex justify-content-between fw-300">
                Receive Type
                <span>{props.affiliate_transfer_type}</span>
              </div>
            )}
            {/* {!props.affiliate_transfer_type && ( */}
            <div className="mb-2 text-gray d-flex justify-content-between fw-300">
              {paymentSelected?.payment_key === "gcash_payout" ||
              paymentSelected?.payment_key === "maya_payout" ||
              paymentSelected?.payment_key === "limepay_mobile_money_payout" ||
              wallet_address
                ? locale.t("withdraw_info.fee")
                : locale.t("deposit_step.second_step.fee")}
              <span>
                0.00{" "}
                <span className="fs-10">
                  {wallet_address ? currencySelected?.currency : "USD"}
                </span>
              </span>
            </div>
            {/* )} */}
            {wallet_address &&
            props?.payment_key !== "gcash_payout" &&
            props?.payment_key !== "limepay_mobile_money_payout" &&
            props?.payment_key !== "maya_payout" ? (
              <div className="mt-2 mb-2 text-gray d-flex align-items-center justify-content-between fw-300">
                <div>{locale.t("withdraw_info.total_amount")}</div>
                <div className="text-black">
                  <b className="fs-14">
                    {(props.payment_key === "b2binpay" ||
                      paymentSelected?.payment_key === "b2binpay") && (
                      <span>
                        {props.amount_local
                          ? (props.amount_local / props.usd_rate).toFixed(6)
                          : (renderRateWithdrawTotal() / cryptoRate).toFixed(6)}
                      </span>
                    )}
                    {props.payment_key !== "b2binpay" &&
                      paymentSelected?.payment_key !== "b2binpay" &&
                      Math.abs(fCurrencySymbol(amount))}{" "}
                    USD
                  </b>
                </div>
              </div>
            ) : (
              <>
                {localamount !== 0 && !props.affiliate_transfer_type && (
                  <div className="mt-2 mb-2 text-gray d-flex align-items-center justify-content-between fw-300">
                    <div>{locale.t("withdraw_info.local_amount")}</div>
                    {type !== "compensation" ? (
                      <div className="text-black">
                        <b>{fCurrencySymbol(localamount)}</b>&nbsp;
                        <b className="fs-10">{profileCountry?.symbol}</b>
                      </div>
                    ) : (
                      <div className="text-black">
                        <b>{fCurrencySymbol(localamount)}</b>&nbsp;
                        <b className="fs-10">USD</b>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {isPayment && (
        <div className="text-center mt-3">
          {locale.t("withdraw_info.withdraw_processing")}
        </div>
      )}
      <div className="d-flex justify-content-between mt-5">
        <Button
          className="btn-outline-iux  mr-1 fw-bold button-default-w "
          onClick={() => onSaveImages()}
        >
          {locale.t("withdraw_info.save")}
        </Button>

        <Button
          type="primary"
          className="btn-primary-new-iux button-default-w "
          onClick={onClose}
        >
          {locale.t("deposit_info.done")}

          {/* {locale.t("register_button_text")} */}
        </Button>
      </div>
    </div>
  );
}

export default PaymentSlip;
