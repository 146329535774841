import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import Connect from "./Connect";
import { Spin } from "antd";
import { auth } from "../firebase";
import RedirectPage from "../redirect";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../actions";
import { INITIALIZE_LOADING } from "../types";
import { useLocation } from "react-router-dom";

const Private = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const isInitializeLoading = useSelector(
    (state) => state.auth.isInitializeLoading
  );
  const isuser = useSelector((state) => state.user);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);

        // console.log("fetch");
        // if (!isuser?.isAuth) {
        dispatch(getUser());
        // }
      } else {
        dispatch({
          type: INITIALIZE_LOADING,
          payload: false,
        });
      }
      // console.log(user);
      setIsLoading(false);
    });
    return () => unsubscribe();
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoading || isInitializeLoading ? (
          <RedirectPage />
        ) : (
          // <RedirectPage />
          <Connect
            mapStateToProps={(state) => {
              return {
                loading: state.user.loading,
                isAuth: state.user.isAuth,
                accessToken: state.user.accessToken,
              };
            }}
            mapDispatchToProps={{}}
          >
            {({ isAuth, accessToken, loading }) => {
              return user ? (
                <Spin spinning={loading}>
                  <Component {...props} />
                </Spin>
              ) : (
                <Redirect
                  to={{
                    pathname: `/${localStorage.getItem("locale")}/login`,
                  }}
                />
              );
            }}
          </Connect>
        )
      }
    />
  );
};
export { Private };
