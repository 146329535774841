import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Layout,
  Button,
  Modal,
  Input,
  Select,
  Form,
  message,
  Alert,
  Popover,
  Divider,
} from "antd";
import { StyledAccountWallet, SubStyledAccountWallet } from "../../components";
import mybonus from "../../mock/mybonus.json";
import RealAccountCardx from "../../components/account-card/StandardXCard";
import { getAccount } from "../../actions";
import { post, get } from "../../helper/request";
import RcQueueAnim from "rc-queue-anim";
import {
  addAccount,
  updateLaverage,
  deleteAccount,
  archiveAccount,
  getArchive,
} from "../../actions";
import { connect, useSelector } from "react-redux";
import VerifyUser from "./fund/verifies/VerifyUser";
import {
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useParams, Link, useHistory } from "react-router-dom";
import CreateAccount from "./Account/CreateAccount";
import DeleteAccount from "./Account/DeleteAccount";
import FooterBonus from "../../components/footer-bonus/FooterBonus";
import { renderApiDomain } from "../../helper/renderApiDomain";
import bonusCredit from "../../helper/bonusCredit";

const locale = require("react-redux-i18n").I18n;
const { confirm } = Modal;
const { Option } = Select;
const refAdd = React.createRef();
const refAddMobile = React.createRef();
const refEdit = React.createRef();
const refEditInvestor = React.createRef();
const refLeverage = React.createRef();
const styleInput = {
  // height: 42,
};
const Accounts = (props) => {
  const { type } = useParams();
  const history = useHistory();
  const accountTypeList = useSelector((state) => state.account.accountTypeList);
  const isDetail =
    accountTypeList && accountTypeList.find((e) => e.display === type);

  const dispatch = useDispatch();
  const isPlatform = props.selectPlatform.toUpperCase();
  const [form] = Form.useForm();
  const [accounts, setAccounts] = useState(props.user.accounts);
  const [isAccount, setIsAccount] = useState(null);
  const [modalDeleteAccount, setModalDeleteAccount] = useState(false);
  const [modalCreateVisible, setModalCreateVisible] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [hoverInformation, setHoverInformation] = useState(false);
  const [modalEditPassword, setModalEditPassword] = useState(null);
  const [modalEditInvestorPassword, setModalEditInvestorPassword] =
    useState(null);
  const user = useSelector((state) => state.user);
  const [modalEditLeverage, setModalEditLeverage] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [active, setActive] = useState({ mt4: false, mt5: false, key: "MT4" });

  useEffect(() => {
    if (accounts !== props.user.accounts) setAccounts(props.user.accounts);
    setActive({ mt4: true, mt5: false, key: "MT4" });
  }, []);

  const onEdit = async () => {
    try {
      setButtonLoading(true);
      const values = await form.validateFields();
      post(renderApiDomain() + "user/account/password/change", {
        account_trade_id: modalEditPassword.id,
        newPassword: values.new_password,
        reset_code: values.reset_code,
      })
        .then((res) => {
          refEdit.current.resetFields();
          setModalEditPassword(null);
          // window.location.reload();
          message.success("Update success!");
          setButtonLoading(false);
          window.location.reload();
        })
        .catch((e) => {
          message.error(e.response.data.error);
          setButtonLoading(false);
        });
    } catch (error) {
      console.log(error);
      setButtonLoading(false);
    }
  };

  const onEditInvestor = async () => {
    try {
      setButtonLoading(true);
      const values = await form.validateFields();
      post(renderApiDomain() + "user/account/password-investor/change", {
        account_trade_id: modalEditInvestorPassword.id,
        newPassword: values.new_password_investor,
        reset_code_investor: values.reset_code_investor,
      })
        .then((res) => {
          refEditInvestor.current.resetFields();
          setModalEditPassword(null);
          message.success("Update success!");
          setButtonLoading(false);
          // window.location.reload();
        })
        .catch((e) => {
          message.error(e?.response?.data?.error);
          setButtonLoading(false);
        });
    } catch (error) {
      message.error(error?.response?.data?.error);
      setButtonLoading(false);
    }
  };

  const onEditLeverage = async () => {
    try {
      setButtonLoading(true);
      const values = await form.validateFields();
      post(renderApiDomain() + "user/account/leverage/change", {
        account_trade_id: modalEditLeverage.id,
        leverage: values.edit_leverage,
      })
        .then((res) => {
          refLeverage.current.resetFields();
          setModalEditLeverage(null);
          message.success("Update success!");
          dispatch(getAccount());
          setButtonLoading(false);
        })
        .catch((e) => {
          try {
            message.error(e.response?.data?.message);
            setButtonLoading(false);
          } catch (error) {
            message.error(error.message);
            setButtonLoading(false);
          }
        });
    } catch (error) {
      setButtonLoading(false);
      message.error(
        error.response?.data?.error
          ? error?.response?.data?.error
          : "Some thing went wrong"
      );
    }
  };

  const onSync = async (account) => {
    setButtonLoading(true);
    const index = accounts.indexOf(account);
    accounts[index].loading = true;
    setAccounts(accounts);
    try {
      get(renderApiDomain() + `user/account/sync/${accounts[index].id}`)
        .then((res) => {
          message.success("Sync Success..");
          accounts[index].current_balance = res.data.data.balance;
          accounts[index].current_credit = res.data.data.credit;
          accounts[index].loading = false;
          setAccounts(accounts);
          setButtonLoading(false);
          dispatch(getAccount());
        })
        .catch((e) => {
          try {
            message.error(e.response.data.error);
          } catch (error) {
            message.error(error.message);
          }
          accounts[index].loading = false;
          setAccounts(accounts);
        });
    } catch (error) {
      setButtonLoading(false);
    }
  };

  function onEditLeverageShow(account) {
    setModalEditLeverage(account);
  }

  function onEditPasswordShow(account) {
    try {
      get(
        renderApiDomain() +
          `user/account/password/request-reset_code/${account.id}`
      );
      setModalEditPassword(account);
    } catch (e) {
      console.log(e.response?.error);
      try {
        message.error(e.response?.data?.message);
      } catch (e) {
        message.error(e.response?.error);
      }
    }
  }
  useEffect(() => {
    if (isAccount) {
      setModalDeleteAccount(true);
    }
  }, [isAccount]);
  function onDelete(account) {
    setIsAccount(account);

    // confirm({
    //   title: (
    //     <div className="text-center fw-700 fs-16">Delete Account Trade</div>
    //   ),
    //   content: (
    //     <div>
    //       <div className="w-100  d-flex justify-content-center mt-4">
    //         <img src="/assets/images/verify/reject.svg" alt="reject" />
    //       </div>
    //       {locale.t("dashboardWallet.menu.confirm_dialog.title")} :
    //       {account.account_number}
    //       {locale.t("dashboardWallet.menu.confirm_dialog.content")}
    //     </div>
    //   ),
    //   onOk() {
    //     return new Promise((resolve, reject) => {
    //       post(renderApiDomain() + "user/account/destroy", {
    //         account_trade_id: account.id,
    //       })
    //         .then((res) => {
    //           props.deleteAccount(accounts, account);
    //           resolve();
    //         })
    //         .catch((e) => {
    //           message.error(e.response.data.error);
    //           reject();
    //         });
    //     }).catch(() => console.log("Oops errors!"));
    //   },
    //   onCancel() {
    //     console.log("Cancel");
    //   },
    // });
  }
  const onDeleteAccount = async (account) => {
    try {
      const { data } = await post(renderApiDomain() + "user/account/destroy", {
        account_trade_id: account.id,
      });
      props.deleteAccount(accounts, account);
      setIsAccount(null);
      setModalDeleteAccount(false);
      message.success(
        `Account ${account.account_number}  has been deleted Successfully`
      );
    } catch (error) {
      message.error(error?.response?.data?.error);
    }
  };
  const onArchive = (acc) => {
    if (acc.current_balance > 0) {
      message.warning(locale.t("dashboardWallet.archive"));
    } else {
      get(renderApiDomain() + `user/account/archive/${acc.id}`)
        .then((res) => {
          props.archiveAccount(accounts, acc);
          props.getArchive();
          message.success(
            `Account ${acc.account_number}  has been archived Successfully`
          );
        })
        .catch((response) => {
          console.log(response);
        });
    }
  };

  function onEditInvestorPasswordShow(account) {
    try {
      get(
        renderApiDomain() +
          `user/account/password-investor/request-reset_code/${account.id}`
      );
      setModalEditInvestorPassword(account);
    } catch (e) {
      console.log(e.response?.error);

      try {
        message.error(e.response?.data?.message);
      } catch (e) {
        message.error(e?.response?.error);
      }
    }
  }

  const validatorPassword = ({ getFieldValue }) => ({
    validator(rule, value) {
      // const regex = new RegExp(
      //   /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/
      // );
      const regex = new RegExp(
        /^(?=.*[0-9])(?=.*[~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_]{8,15}$/
      );
      if (value) {
        if (value?.match(regex)) {
          return Promise.resolve();
        } else {
          return Promise.reject(
            locale.t("dashboardWallet.modal.accountPassword_validate")
          );
        }
      } else {
        return Promise.reject(locale.t("dashboardWallet.input_error"));
      }

      // if (value) {
      //   return Promise.reject(
      //     locale.t("dashboardWallet.modal.accountPassword_validate")
      //   );
      // }
      // } else {
      //   return Promise.reject(locale.t("dashboardWallet.input_error"));
      // }
    },
  });

  const validatorInvestorPassword = ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = new RegExp(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/
      );
      if (value.match(regex)) {
        return Promise.resolve();
      }
      return Promise.reject(
        locale.t("dashboardWallet.modal.accountPasswordInvestor")
      );
    },
  });
  const onCloseCreateAccount = () => {
    setModalCreateVisible(false);
  };
  const onCloseDeleteAccount = () => {
    setModalDeleteAccount(false);
  };
  const showInformation = () => {
    setHoverInformation(!hoverInformation);
  };

  const isMobile = window.innerWidth < 800;
  const renderBg = (id) => {
    switch (id) {
      case "Standard":
        return "#1CB894";
      case "Standard+":
        return "#009EFF";
      case "Raw":
        return "#B44844";
      case "Pro":
        return "#7E0BD9";

      default:
        return "bg-primary";
    }
  };

  const [bonusData, setBonusData] = useState([]);

  useEffect(() => {
    const fetchBonus = async () => {
      const bonusData = await bonusCredit();
      setBonusData(bonusData);
    };
    fetchBonus();
  }, []);

  return (
    <RcQueueAnim duration={2000}>
      <Layout className="max-width-layout">
        <SubStyledAccountWallet>
          <div
            className="d-flex iux-borderBottomx align-items-center pl-3 pr-3"
            key={"1"}
          >
            <div
              className="d-flex"
              style={{ padding: "0px 6px 0px 6px", borderRight: "1px dashed" }}
            >
              <div>
                <div
                  className={`accounts-logo `}
                  style={{ background: renderBg(isDetail?.display) }}
                >
                  {isDetail?.display?.charAt(0)}
                </div>
              </div>
              <div className="col-md">
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "700",
                    fontFamily: "sans-serif",
                  }}
                >
                  <b>{isDetail && isDetail?.display}</b>
                </p>
                <p style={{ fontSize: "12px", textTransform: "uppercase" }}>
                  Account
                </p>
              </div>
            </div>
            <div className="col-md" style={{ padding: "0px 18px" }}>
              <div className="d-flex">
                <div className="minWallet-w100">
                  <p>{locale.t("dashboardWallet.account_detail")}</p>
                </div>
                <div className="color-textbox">
                  <p className="hide-responsive">
                    <Popover
                      title={isDetail?.display}
                      trigger="focus"
                      placement="bottom"
                      content={
                        <div>
                          {`${locale.t("account_detail.detail")} : ${
                            isDetail?.minimum_spread
                          } PIPs`}
                          <br />
                          {`${locale.t("account_detail.detail4")} : 1:${
                            isDetail?.maximum_leverage
                          }`}
                          <br />
                          {`${locale.t("account_detail.detail3")} : $${
                            isDetail?.commission
                          } ${
                            isDetail?.commission !== 0
                              ? `(${locale.t("perlot")})`
                              : ""
                          }`}
                          <br />
                          {`${locale.t("account_detail.detail2")} : $${
                            isDetail?.minimum_deposit
                          }`}
                          <br />
                          {isDetail?.name === "StandardBonus" &&
                            `${locale.t("account_detail.detail7")} : ${
                              bonusData[0]?.bonus_pct
                            }% and ${bonusData[1]?.bonus_pct}%`}
                        </div>
                      }
                    >
                      <span className="text-lightgray">
                        <InfoCircleOutlined
                          onClick={showInformation}
                          className="ml-2 "
                        />
                      </span>
                    </Popover>
                  </p>
                  <div className="show-responsive">
                    <Popover
                      title={isDetail?.display}
                      trigger="focus"
                      placement="bottom"
                      content={
                        <div>
                          {`${locale.t("account_detail.detail")} : ${
                            isDetail?.minimum_spread
                          } PIPs`}
                          <br />
                          {`${locale.t("account_detail.detail4")} : 1:${
                            isDetail?.maximum_leverage
                          }`}
                          <br />
                          {`${locale.t("account_detail.detail3")} : $${
                            isDetail?.commission
                          } ${isDetail?.commission !== 0 ? "(per lot)" : ""}`}
                          <br />
                          {`${locale.t("account_detail.detail2")} : $${
                            isDetail?.minimum_deposit
                          }`}
                          {/* <br />
                          {`${locale.t("account_detail.detail5")} : ${
                            isDetail?.swap
                          }`} */}
                        </div>
                      }
                    >
                      <span className="text-lightgray">
                        <InfoCircleOutlined
                          onClick={showInformation}
                          className="ml-2 "
                        />
                      </span>
                    </Popover>
                  </div>
                </div>
              </div>
            </div>
            {props.user.userVerify.status === "accepted" &&
              isDetail?.active === 1 && (
                <Button
                  className="btn-primary-new-outline d-lg-block d-md-block d-none d-xl-block"
                  onClick={() => {
                    history.replace(
                      `/${localStorage.getItem(
                        "locale"
                      )}/wallet/account/openaccount?typeAccount=${
                        isDetail.display === "Raw" || isDetail.display === "Pro"
                          ? "Professional"
                          : "Recommended"
                      }`
                    );
                  }}
                >
                  <p className="hide-create">
                    {locale.t("dashboardWallet.createAccount")}
                  </p>
                  <p className="hide-desktop">+</p>
                </Button>
              )}
          </div>
          {/* BTN2 */}
          <div className="d-md-none mt-3 mt-md-0 text-center">
            {props.user.userVerify.status === "accepted" &&
              isDetail?.active === 1 &&
              accounts.filter(
                (account) =>
                  account.type === "real" &&
                  account.account_type === isDetail?.name &&
                  account.platform === isPlatform
              ).length > 0 && (
                <Button
                  className={
                    isPlatform !== "MT4"
                      ? " btn-outline-iux"
                      : "btn-disabled-iux"
                  }
                  onClick={() => {
                    history.replace(
                      `/${localStorage.getItem(
                        "locale"
                      )}/wallet/account/openaccount?typeAccount=${
                        isDetail.display === "Raw" || isDetail.display === "Pro"
                          ? "Professional"
                          : "Recommended"
                      }`
                    );
                  }}
                >
                  <p className="">
                    {isPlatform !== "MT4"
                      ? locale.t("dashboardWallet.createAccount")
                      : locale.t("dashboardWallet.only_mt5")}
                  </p>
                </Button>
              )}
          </div>
          <div className="mt-4">
            <div>
              {/**
               * Edit leverage
               */}
              <Form form={form} ref={refLeverage}>
                <Modal
                  className="w-modal width-modal-openAccount a-modal"
                  visible={modalEditLeverage !== null}
                  title={locale.t("dashboardWallet.menu.leverage_edit")}
                  onCancel={() => {
                    setModalEditLeverage(null);
                  }}
                  footer={[
                    <div className="d-flex justify-content-between text-center">
                      <Button
                        className="btn-outline-iux"
                        key="back"
                        onClick={() => {
                          setModalEditLeverage(null);
                        }}
                      >
                        {locale.t("dashboardWallet.modal.cancel")}
                      </Button>

                      <Button
                        className="btn-primary-new-iux w-100"
                        key="submit"
                        type="primary"
                        onClick={() => {
                          onEditLeverage();
                        }}
                        loading={buttonLoading}
                      >
                        {locale.t("dashboardWallet.modal.confirm")}
                      </Button>
                    </div>,
                  ]}
                >
                  <div>
                    <Form.Item
                      name="edit_leverage"
                      rules={[
                        {
                          required: true,
                          message: locale.t("dashboardWallet.input_error"),
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        placeholder="Leverage"
                        className="user"
                      >
                        {props.user?.leverages[isDetail?.name] &&
                          props.user.leverages[isDetail?.name].map((e, i) => (
                            <Option value={e.values} key={i}>
                              <div>
                                <div className="row">
                                  <div className="col-8 pr-0">
                                    <div className="">1:{e.values} &nbsp;</div>
                                    <Divider className="m-0" />
                                  </div>
                                  {(e.values === 1 ||
                                    e.values === 100 ||
                                    e.values === 1000) && (
                                    <div
                                      className={`${
                                        e.values >= 1000
                                          ? "text-danger"
                                          : e.values < 100
                                          ? "text-primary-new-iux"
                                          : "text-yellow-new-iux"
                                      } col-4 text-right pl-0`}
                                    >
                                      <div className=""> {e.title}</div>
                                      <Divider
                                        className={`${
                                          e.values >= 1000
                                            ? "bg-danger"
                                            : e.values < 100
                                            ? "bg-primary-iux"
                                            : "bg-yellow-iux"
                                        } m-0`}
                                        style={{
                                          background:
                                            e.values >= 1000
                                              ? "#B44844"
                                              : e.values < 100
                                              ? "#1cb894"
                                              : "rgb(241, 158, 3)",
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Modal>
              </Form>

              {/**
               * Edit Password Modal
               */}
              <Form form={form} ref={refEdit} className="user-change">
                <Modal
                  className="w-modal width-modal-openAccount user-change"
                  visible={modalEditPassword !== null}
                  title={locale.t("dashboardWallet.menu.account_edit")}
                  onCancel={() => {
                    form.resetFields();
                    setModalEditPassword(null);
                  }}
                  footer={[
                    <div className="d-flex justify-content-between text-center px-0">
                      <Button
                        className="btn-outline-iux "
                        key="back"
                        onClick={() => {
                          form.resetFields();
                          setModalEditPassword(null);
                        }}
                      >
                        {locale.t("dashboardWallet.modal.cancel")}
                      </Button>

                      <Button
                        className="btn-primary-new-iux w-100"
                        key="submit"
                        onClick={() => {
                          onEdit();
                        }}
                        loading={buttonLoading}
                      >
                        {locale.t("dashboardWallet.modal.confirm")}
                      </Button>
                    </div>,
                  ]}
                >
                  <div>
                    <Form.Item
                      name="new_password"
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                        validatorPassword,
                      ]}
                      className="icon-user"
                    >
                      <Input.Password
                        type="password"
                        placeholder={locale.t(
                          "dashboardWallet.modal.accountPassword"
                        )}
                        style={styleInput}
                        className="icon-user user-password"
                      />
                    </Form.Item>
                    <Form.Item
                      name="new_password_confirm"
                      dependencies={["new_password"]}
                      rules={[
                        {
                          required: true,
                          message: locale.t("dashboardWallet.input_error"),
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (
                              !value ||
                              getFieldValue("new_password") === value
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              locale.t("menuWallet.notmathPassword")
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        type="password"
                        placeholder={locale.t(
                          "dashboardWallet.modal.accountConfirmPassword"
                        )}
                        style={styleInput}
                        className="icon-user user-password"
                      />
                    </Form.Item>
                    <Form.Item
                      name="reset_code"
                      rules={[
                        {
                          required: true,
                          message: locale.t("dashboardWallet.input_error"),
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        placeholder={`${locale.t(
                          "dashboardWallet.modal.code"
                        )} ${user.otp_to === "phone" ? "SMS" : "Email"}`}
                        style={styleInput}
                      />
                    </Form.Item>
                  </div>
                </Modal>
              </Form>
              {/***
               * Edit Investor Modal
               */}
              <Form form={form} ref={refEditInvestor}>
                <Modal
                  className="w-modal width-modal-openAccount user-change"
                  visible={modalEditInvestorPassword !== null}
                  title={locale.t("dashboardWallet.menu.investor_edit")}
                  onCancel={() => {
                    setModalEditInvestorPassword(null);
                  }}
                  footer={[
                    <div className="d-flex justify-content-between text-center">
                      <Button
                        className="btn-outline-iux"
                        key="back"
                        onClick={() => {
                          setModalEditInvestorPassword(null);
                        }}
                      >
                        {locale.t("dashboardWallet.modal.cancel")}
                      </Button>

                      <Button
                        className="btn-primary-new-iux w-100"
                        key="submit"
                        type="primary"
                        onClick={() => {
                          onEditInvestor();
                        }}
                        loading={buttonLoading}
                      >
                        {locale.t("dashboardWallet.modal.confirm")}
                      </Button>
                    </div>,
                  ]}
                >
                  <div>
                    <Form.Item
                      name="new_password_investor"
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                        validatorPassword,
                      ]}
                    >
                      <Input.Password
                        type="password"
                        placeholder={locale.t(
                          "dashboardWallet.modal.accountPasswordInvestor"
                        )}
                        style={styleInput}
                        className="user-password"
                      />
                    </Form.Item>
                    <Form.Item
                      name="new_password_investor_confirm"
                      dependencies={["new_password_investor"]}
                      rules={[
                        {
                          required: true,
                          message: locale.t("dashboardWallet.input_error"),
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (
                              !value ||
                              getFieldValue("new_password_investor") === value
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              locale.t("menuWallet.notmathPassword")
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        type="password"
                        placeholder={locale.t(
                          "dashboardWallet.modal.accountConfirmPasswordInvestor"
                        )}
                        style={styleInput}
                        className="user-password"
                      />
                    </Form.Item>
                    <Form.Item
                      name="reset_code_investor"
                      rules={[
                        {
                          required: true,
                          message: locale.t("dashboardWallet.input_error"),
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        placeholder={`${locale.t(
                          "dashboardWallet.modal.code"
                        )} ${user.otp_to === "phone" ? "SMS" : "Email"}`}
                        style={styleInput}
                      />
                    </Form.Item>
                  </div>
                </Modal>
              </Form>

              {/***
               * Create Account Modal
               */}
              <CreateAccount
                isOpen={modalCreateVisible}
                onClose={onCloseCreateAccount}
                onDeleteAccount={onDeleteAccount}
              />
              {/*  */}
            </div>
          </div>

          {props.user.userVerify.status === "accepted" ? (
            accounts?.filter(
              (account) =>
                account.type === "real" &&
                account.account_type === isDetail?.name &&
                account.platform === isPlatform
            ).length > 0 ? (
              <div
                key={"3"}
                className="row "
                style={{
                  margin: "12px",
                  display: isMobile ? (mobile ? "none" : "") : "",
                }}
              >
                {accounts
                  ?.filter(
                    (account) =>
                      account.type === "real" &&
                      account.account_type === isDetail?.name &&
                      account.platform === isPlatform
                  )
                  .map((account, index) => (
                    <div key={index} className="col-md minandmax-width">
                      <RealAccountCardx
                        account={account}
                        onEditLeverageShow={onEditLeverageShow}
                        onEditPasswordShow={onEditPasswordShow}
                        onEditInvestorPasswordShow={onEditInvestorPasswordShow}
                        onDelete={onDelete}
                        onArchive={onArchive}
                        key={isDetail?.display + "_" + index}
                        onSync={onSync.bind(this, account)}
                        display={isDetail?.display}
                      />
                    </div>
                  ))}
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center flex-column pt-5 pb-5">
                <img
                  src="/assets/images/icons/none-account.svg"
                  alt="tranfer-icons"
                />
                <p className="my-4">{locale.t("dashboardWallet.notAccount")}</p>
                {isDetail?.active === 1 && (
                  <Button
                    onClick={() => {
                      history.replace(
                        `/${localStorage.getItem(
                          "locale"
                        )}/wallet/account/openaccount?typeAccount=${
                          isDetail.display === "Raw" ||
                          isDetail.display === "Pro"
                            ? "Professional"
                            : "Recommended"
                        }`
                      );
                    }}
                    className="mt-4 btn-primary-new-iux"
                    htmlType="submit"
                    type="primary"
                  >
                    <p className="">
                      + {locale.t("dashboardWallet.createAccount")}
                    </p>
                  </Button>
                )}
              </div>
            )
          ) : (
            <VerifyUser />
          )}
          <DeleteAccount
            isOpen={modalDeleteAccount}
            onClose={onCloseDeleteAccount}
            onDeleteAccount={onDeleteAccount}
            account={isAccount}
          />
        </SubStyledAccountWallet>
        {type === "Standard+" && <FooterBonus />}
      </Layout>
    </RcQueueAnim>
  );
};

const mapStateToProps = ({ user, i18n }) => {
  return { user, locale: i18n.locale };
};

export default connect(mapStateToProps, {
  addAccount,
  getArchive,
  updateLaverage,
  archiveAccount,
  deleteAccount,
})(Accounts);
