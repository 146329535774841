import {
  UPDATE_LOADING,
  AUTH_SUCCESS,
  UPDATE_USER_PROFILE,
  SET_PROFILE_LOADING,
  FETCH_USER_ACCOUNT,
  SET_PROFILE,
  SET_ARCHIVE,
  LOGOUT,
  UPDATE_LOCALE,
  UPDATE_VERIFIER_USER,
  ADD_ACCOUNT,
  DELETE_ACCOUNT,
  ARCHIVE_ACCOUNT,
  RESTORE_ACCOUNT,
  UPDATE_ACCOUNT,
  UPDATE_GETBONUS,
  UPDATE_VERIFIER_BOOKBANK,
  SET_BANK,
  SET_IPADDRESS,
  SET_ACCOUNT,
  SET_USER_VERIFY,
  UPDTAE_ACCOUNT,
  SET_USER_VERIFY_BOOKBANK,
  SET_LEVERAGE,
  SET_COUNTRIES,
  SET_BONUS_CONFIG,
  SET_PROFILE_COUNTRIES,
  UPDATE_2FA,
  UPDATE_IS_AUTH,
} from "../types";

const INITIALIZE_STATE = {
  isAuth: (localStorage.getItem("isAuth") || "false") === "true",
  accessToken: localStorage.getItem("access_token") || "",
  expires_in: localStorage.getItem("expires_in") || "",
  refresh_token: localStorage.getItem("refresh_token") || "",
  profile: "",
  otp_to: "",
  server_status: 1,
  accounts: [],
  royal_class: "",
  loading: false,
  enabled_2fa: false,
  locale: localStorage.getItem("locale") || "th",
  userVerify: {
    status: null,
    userVerifyInfo: null,
  },
  bookBankVerify: {
    status: null,
    id: null,
    user_id: null,
    bank_name: null,
    bank_account_name: null,
    bank_account_number: null,
    book_bank: null,
    country_id: null,
    reason: null,
    current: null,
    flags: null,
    actionAt: null,
    action_seconds: null,
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
  },
  accountTradeBonusId: {
    isRecived: true,
    bonusId: null,
    status: null,
  },
  leverages: {},
  bonus_balance_30: 0,
  bonus_balance_15: 0,
  user_agreement: {},
  ipAddress: "",
  bonus_config: {},
};

export default (state = INITIALIZE_STATE, action) => {
  switch (action.type) {
    case SET_PROFILE:
      return {
        ...state,
        profile: action.payload.personal_detail || null,
        loading: false,
        isAuth: true,
        otp_to: action.payload.otp_to || null,
        server_status: action.payload.server_status || null,
        partner: action.payload.partner || 0,
        affiliate_id: action.payload.affiliate_id || null,
        accountTradeBonusId:
          {
            isRecived: action.payload.account_trade_bonus_id !== null || null,
            bonusId: action.payload.account_trade_bonus_id || null,
            status: action?.payload?.bonus_account?.status || null,
          } || null,
        // accounts: action.payload.account_trade || null,
        // userVerify:
        //   {
        //     status:
        //       action.payload.user_verify.user_verify_infomation.status || null,
        //     userVerifyInfo:
        //       action.payload.user_verify.user_verify_infomation
        //         .accepted_verify || null,
        //   } || null,
        // bookBankVerify:
        //   {
        //     status:
        //       action.payload.user_verify.user_account_verify_infomation
        //         .status || null,
        //     bookBankVerifyInfo:
        //       action.payload.user_verify.user_account_verify_infomation
        //         .accepted_verify_bank_data || null,
        //   } || null,
        bonus_balance_30: action.payload.a_credit_remain || 0,
        bonus_balance_15: action.payload.b_credit_remain || 0,
        royal_class: action.payload.royal_class || null,
        user_agreement: action.payload.user_agreement || null,
        bonus_account: action.payload.bonus_account,
        session_id: action?.payload?.session_id,
        enabled_2fa: action?.payload?.enabled_2fa || false,
      };
    case SET_ARCHIVE:
      return {
        ...state,
        archive: action.payload,
      };
    case SET_BONUS_CONFIG:
      return {
        ...state,
        bonus_config: action.payload,
      };
    case SET_ACCOUNT:
      return {
        ...state,
        accounts: action.payload,
      };
    case UPDTAE_ACCOUNT:
      return {
        ...state,
        accounts: action.payload,
      };
    case SET_USER_VERIFY:
      return {
        ...state,
        userVerify: action.payload,
      };
    case SET_USER_VERIFY_BOOKBANK:
      return {
        ...state,
        bookBankVerify: action.payload,
      };
    case SET_IPADDRESS:
      return {
        ...state,
        ipAddress: action.payload,
      };
    case SET_BANK:
      return {
        ...state,
        bank: action.payload,
      };
    case SET_PROFILE_COUNTRIES:
      return {
        ...state,
        profileCountry: action.payload,
      };
    case SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case SET_LEVERAGE:
      return {
        ...state,
        leverages: action.payload,
      };
    case AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        accessToken: action.payload.access_token,
        expires_in: action.payload.access_token_expires_at,
        refresh_token: action.payload.refresh_token,
      };
    case LOGOUT:
      return {
        ...INITIALIZE_STATE,
        accessToken: "",
        expires_in: 0,
        refresh_token: "",
        profile: null,
        isAuth: false,
      };
    case UPDATE_LOADING:
      return { ...state, loading: action.payload };
    case UPDATE_USER_PROFILE:
      return { ...state, profile: action.payload };
    case SET_PROFILE_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case FETCH_USER_ACCOUNT: {
      return {
        ...state,
        accounts: action.payload,
        loading: false,
      };
    }

    case UPDATE_LOCALE: {
      return {
        ...state,
        locale: action.locale,
      };
    }

    case ADD_ACCOUNT: {
      return {
        ...state,
        accounts: action.payload,
      };
    }

    case UPDATE_ACCOUNT: {
      return {
        ...state,
        accounts: action.payload,
      };
    }

    case DELETE_ACCOUNT: {
      return {
        ...state,
        accounts: action.payload,
      };
    }

    case ARCHIVE_ACCOUNT: {
      return {
        ...state,
        accounts: action.payload,
      };
    }

    case RESTORE_ACCOUNT: {
      return {
        ...state,
        accounts: action.payload,
      };
    }

    case UPDATE_VERIFIER_USER: {
      return {
        ...state,
        userVerify: action.payload,
      };
    }

    case UPDATE_VERIFIER_BOOKBANK: {
      return {
        ...state,
        bookBankVerify: action.payload,
      };
    }

    case UPDATE_GETBONUS: {
      return {
        ...state,
        accountTradeBonusId: {
          isRecived: action.payload,
          bonusId: null,
        },
      };
    }
    case UPDATE_2FA: {
      return {
        ...state,
        enabled_2fa: action?.payload,
      };
    }
    case UPDATE_IS_AUTH: {
      return {
        ...INITIALIZE_STATE,
        isAuth: false,
      };
    }
    default:
      return state;
  }
};
