import { Button, message, Modal, Steps } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { signInWithCustomToken } from "@firebase/auth";
import { auth } from "../../firebase";
import { getUserv2 } from "../../actions";
import { getUserCustomToken } from "../../api/verifyuser";
import { getFirebaseCustom } from "../../api/account";
const locale = require("react-redux-i18n").I18n;
function Modal2fa(props) {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const [openModal2fa, setOpenModal2fa] = useState(false);

  useEffect(() => {
    if (user?.enabled_2fa !== 1 && !localStorage.getItem("2fa")) {
      //   fetchCustomToken();
      setOpenModal2fa(true);
    }
  }, [user]);
  useEffect(() => {
    if (!localStorage.getItem("2fa")) {
      fetchCustomToken();
    }
  }, []);
  const onClose = (second) => {
    setOpenModal2fa(false);
  };
  const fetchCustomToken = async () => {
    try {
      const { data } = await getUserCustomToken();

      signInWithCustomToken(auth, data.data.token)
        .then((userCredential) => {})
        .catch((error) => {
          const errorCode = error.code;
          switch (errorCode) {
            case "auth/user-not-found":
              message.error(
                "Incorrect email or password. Please check your credentials and try again."
              );
              // setError("User  not found");
              // Handle the error for when the user is not found
              break;
            case "auth/wrong-password":
              message.error(
                "Incorrect email or password. Please check your credentials and try again."
              );
              // setError("password is incorrect");
              // Handle the error for when the password is incorrect
              break;
            case "auth/invalid-email":
              message.error(
                "Incorrect email or password. Please check your credentials and try again."
              );
              // setError("email is invalid");
              // Handle the error for when the email is invalid
              break;
            // Handle other error codes as needed
            default:
              message.error("Something went wrong");
              // setError("Something went wrong");
              // Handle any other errors
              break;
          }
        });
    } catch (error) {
      message.error("something went wrong");
      console.log(error);
    }
  };
  return (
    <Modal
      className="w-modal-Verify width-modal-swap user user-change p-4"
      closable={false}
      visible={openModal2fa}
      footer={
        <>
          <div
            className="text-center fs-14 text-neutral-grey "
            style={{ gap: 20, marginTop: 40 }}
          >
            <p style={{ whiteSpace: "pre-line", fontWeight: 400 }}>
              {" "}
              {locale.t("starting2fa")}{" "}
            </p>
          </div>
        </>
      }
      onCancel={() => {
        onClose();
      }}
    >
      <div className="text-center ">
        <img
          style={{ position: "relative", marginBottom: 40 }}
          alt="IUX"
          className="border-logo"
          src={process.env.PUBLIC_URL + "/assets/images/icons/iux-logo.png"}
          width={80}
        />
        <h4 className="fs-20 fw-700">{locale.t("otp_verification")}</h4>
        <div className="text-neutral-grey ">
          <p className="mt-3 fs-14">{locale.t("twofa.twofa_des")}</p>
        </div>
        <div className=" " style={{ gap: 20, marginTop: 40 }}>
          {" "}
          <Button
            onClick={() => {
              history.push(
                "/" + localStorage.getItem("locale") + "/wallet/setting"
              );
              onClose();
            }}
            className="btn-primary-new-iux w-100 "
            type="primary"
            style={{ borderRadius: 8 }}
          >
            {locale.t("goto_setting")}
          </Button>
          <Button
            onClick={() => {
              localStorage.setItem("2fa", "1");
              //   fetchCustomToken();
              onClose();
            }}
            className="btn-outline-iux w-100 mt-3"
            style={{ borderRadius: 8 }}
          >
            {locale.t("remind_me_later")}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default Modal2fa;
