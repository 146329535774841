import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link, useHistory } from "react-router-dom";
import {
  LogoutAuth,
  getBank,
  getUser,
  getUserLogin,
  getUserv2,
} from "../actions";
import { Base64Decoder } from "../helper/Base64Encoder";
import LoadingIux from "../components/loading";
import { auth, authenticateWithToken } from "../firebase";
import { signInWithCustomToken } from "firebase/auth";
import { message } from "antd";
import { post } from "../helper/request";

function RedirectDashbord() {
  const { account, token } = useParams();
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    if (user?.isAuth) {
      if (token) {
        authenticateWithToken(token);
        // onJoinus();
      } else {
        // history.replace(
        //   `/${localStorage.getItem(
        //     "locale"
        //   )}/wallet/fund/deposit?account=${account}&webtrade=true`
        // );
      }
    }
    if (token) {
      onJoinus();
    }
  }, [token]);
  useEffect(() => {
    if (user?.isAuth) {
      // history.replace(
      //   `/${localStorage.getItem(
      //     "locale"
      //   )}/wallet/fund/deposit?account=${account}&webtrade=true`
      // );
    }
  }, [user?.isAuth]);
  const onJoinus = async () => {
    try {
      if (token) {
        signInWithCustomToken(auth, token)
          .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            console.log("in joinus");
            user
              .getIdToken()
              .then(async (token) => {
                dispatch(getUserv2()).then(async (response) => {
                  let sessionId = await window.seon.getBase64Session();
                  let payload = {
                    session: sessionId,
                    session_id: response?.data?.data?.session_id,
                    source: "web",
                  };
                  try {
                    const { data } = await post(
                      `${process.env.REACT_APP_API_URL}seon`,
                      payload
                    );
                  } catch (error) {
                    console.log(error);
                  }
                  dispatch(
                    getBank(response.data?.data?.personal_detail?.country_id)
                  );
                });
                await dispatch(getUserLogin());
                history.replace(`/${localStorage.getItem("locale")}/wallet`);
              })
              .catch((error) => {
                console.log(error);
                message.error("Something went wrong");
              });

            // ...
          })
          .catch((error) => {
            const errorCode = error.code;
            switch (errorCode) {
              case "auth/user-not-found":
                message.error(
                  "Incorrect email or password. Please check your credentials and try again."
                );
                // setError("User  not found");
                // Handle the error for when the user is not found
                break;
              case "auth/wrong-password":
                message.error(
                  "Incorrect email or password. Please check your credentials and try again."
                );
                // setError("password is incorrect");
                // Handle the error for when the password is incorrect
                break;
              case "auth/invalid-email":
                message.error(
                  "Incorrect email or password. Please check your credentials and try again."
                );
                // setError("email is invalid");
                // Handle the error for when the email is invalid
                break;
              // Handle other error codes as needed
              default:
                message.error("Something went wrong");
                // setError("Something went wrong");
                // Handle any other errors
                break;
            }
          });
      }
    } catch (error) {
      // dispatch(LogoutAuth());
      // history.replace("/en");
      console.log(error);
    }
  };
  return (
    <div>
      <LoadingIux />
    </div>
  );
}

export default RedirectDashbord;
