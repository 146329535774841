import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CheckOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useSelector } from "react-redux";

const locale = require("react-redux-i18n").I18n;

const Card = styled.div`
  background: ${(props) => props.background};
  padding: 20px;
  border-radius: 14px;
  box-shadow: ${(props) => props.boxShadow};
  margin-top: ${(props) => props.marginTop};
`;

const Popluar = styled.div`
  background: #1cb894;
  color: #fff;
  position: absolute;
  top: 18px;
  left: 40%;
  text-transform: uppercase;
  padding: 4px 12px 4px 12px;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
`;

const PopluarCopy = styled.div`
  background: #1cb894;
  text-transform: uppercase;
  color: #fff;
  padding: 4px 12px 4px 12px;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  text-align: center;
`;

const List = styled.div`
  padding: 0.5rem 0;
`;

const ButtonAccount = styled(Button)`
  background-color: ${(props) => props.background} !important;
  border: 1px solid #1cb894 !important;
  color: ${(props) =>
    props.background === "#000" ? "#1cb894" : "#FFF"} !important;
  border-radius: 4px;
  height: 40px;
  &:hover {
    background-color: ${(props) =>
      props.background === "#000" ? "#1cb894" : "#000"} !important;
    color: ${(props) =>
      props.background !== "#000" ? "#1cb894" : "#FFF"} !important;
  }
`;

const account = [
  {
    account: "Standard+",
    title1: "Trading Platform",
    title2: "MetaTrader5",
    title3: "Commission",
    title4: "(per lot)",
    title5: "$0.0",
    title6: "Spread from",
    title7: "(PIPs)",
    title8: "0.5",
  },
  {
    account: "Standard",
    title1: "Trading Platform",
    title2: "MetaTrader5",
    title3: "Commission",
    title4: "(per lot)",
    title5: "$0.0",
    title6: "Spread from",
    title7: "(PIPs)",
    title8: "0.2",
  },
  // {
  //   account: "ECN",
  //   title1: "Trading Platform",
  //   title2: "MetaTrader5",
  //   title3: "Commission",
  //   title4: "(per lot per side)",
  //   title5: "$1.75",
  //   title6: "Spread from",
  //   title7: "(PIPs)",
  //   title8: "0.1",
  // },
  {
    account: "Raw",
    title1: "Trading Platform",
    title2: "MetaTrader5",
    title3: "Commission",
    title4: "(per lot)",
    title5: "$3.5",
    title6: "Spread from",
    title7: "(PIPs)",
    title8: "0.0",
  },
  {
    account: "Pro",
    title1: "Trading Platform",
    title2: "MetaTrader5",
    title3: "Commission",
    title4: "(per lot)",
    title5: "$0.0",
    title6: "Spread from",
    title7: "(PIPs)",
    title8: "0.7",
  },
  // {
  //   account: "Cent",
  //   title1: "Trading Platform",
  //   title2: "MetaTrader5",
  //   title3: "Commission",
  //   title4: "(per lot per side)",
  //   title5: "$0.0",
  //   title6: "Spread from",
  //   title7: "(PIPs)",
  //   title8: "0.2",
  // },
];

const AccountName = ["Raw", "Standard", "Standard+", "Pro"];
const tp = ["	MetaTrader	", "	MetaTrader	", "	MetaTrader	", "	MetaTrader	"];
const Commission = [
  "	 ($3.5 per lot)	",
  "	 ($0.0 per lot)	",
  "	 ($0.0 per lot)	",
  "	 ($0.0 per lot)	",
  // "	$3.5 ($1.75 per lot roundturn)	",
];
const spread = ["	0.0	", "	0.2	", "	0.5	", "0.7"];
const sd = ["	$200	", "	$10	", "	$10	", "	$1000	"];
const lv = ["1:3000", "1:3000", "1:1000", "1:3000"];
const sl = [
  "	London , Asia pacific	",
  "	London , Asia pacific	",
  "	London , Asia pacific	",
  "	London , Asia pacific	",
];
const mlt = ["	0.01	", "	0.01	", "	0.01	", "	0.01	"];
const cp = ["	90+	", "	90+	", "	90+	", "	90+	"];
const ict = ["	TRUE	", "	TRUE	", "	TRUE	", "	TRUE	"];
const sol = ["	0%	", "	35%	", "	0%	", "	0%	"];
const oct = ["	TRUE	", "	TRUE	", "	TRUE	", "	TRUE	"];
const ia = ["	TRUE	", "	TRUE	", "	TRUE	", "	TRUE	"];
const tsa = ["	All	", "	All	", "	All	", "	All	"];
const odr = ["	None	", "	None	", "	None	", "	None	"];
const pl = ["	MQL5	", "	MQL5	", "	MQL5	", "	MQL5	"];
const sf = ["	Day Traders	", "	Day Traders	", "	Day Traders	", "	Day Traders	"];
// const maketable = [ "Account" , Account , { Account }, { Account }];
const textColor = ["#1CB894", "#FFF", "#1CB894", "#1CB894"];
const textColor2 = ["#fff", "#FFF", "#FFF", "#FFF"];
const colors = ["#1CB894", "#000", "#1CB894", "#1CB894"];
const textFormat = [
  "text-center text-white",
  "text-center text-white",
  "text-center text-white",
];

function AccountOverview(props) {
  const accounts = useSelector((state) => state.account);
  const realaccount = accounts?.accountTypeList?.filter(
    (e) => e.active === 1 && e.type === "real"
  );
  return (
    <div className="bg-black">
      <section key={2} className="container ">
        <div className="mt-4 mb-4 ">
          <div className="row">
            {accounts.accountTypeList
              ?.filter((e) => e.active === 1 && e.type === "real")
              ?.map((e, i) => (
                <div className="col-12 col-md-6 col-lg px-1">
                  <Card marginTop={"18px"} background="#242424">
                    <div className="text-gray mt-4">Account</div>
                    {(e.display === "Standard" || e.display === "Raw") && (
                      <Popluar>{locale.t("funding.section_2.hot")}</Popluar>
                    )}

                    <h3
                      className="font-bold-iux "
                      style={{ color: textColor[i] }}
                    >
                      {e.display}
                    </h3>
                    <div className="d-flex flex-column">
                      <div className="d-flex justify-content-between pt-5 pb-5">
                        <div className="text-gray">Trading Platform</div>
                        <div className="text-white fs-16">MetaTrader5</div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center pb-4">
                        <div>
                          <div className="text-gray">Commission</div>
                          <div className="text-gray">(per lot)</div>
                        </div>
                        <h3 className="text-white fs-32 text-right">
                          ${e.commission}
                        </h3>
                      </div>
                      <div className="d-flex justify-content-between align-items-center pt-2 pb-2">
                        <div>
                          <div className="text-gray">Spread from</div>
                          <div className="text-gray">(PIPs)</div>
                        </div>
                        <h3 className="text-white fs-32 text-right">
                          {e.minimum_spread}
                        </h3>
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-center pt-4 pb-2">
                      <Link to={`/${localStorage.getItem("locale")}/login`}>
                        <ButtonAccount background={colors[i]}>
                          {locale.t("button.sub_button.title_1")}
                        </ButtonAccount>
                      </Link>
                      <Link
                        to={`/${localStorage.getItem("locale")}/login`}
                        className="mt-4 text-white"
                      >
                        {locale.t("become_demo")}
                      </Link>
                    </div>
                  </Card>
                </div>
              ))}
            {/* {account.map((item, index) => (
              <div className="col-12 col-md-6 col-lg-3 px-1">
                <Card marginTop={"18px"} background="#242424">
                  <div className="text-gray mt-4">Account</div>
                  {(index === 1 || index === 2) && (
                    <Popluar>{locale.t("funding.section_2.hot")}</Popluar>
                  )}

                  <h3
                    className="font-bold-iux "
                    style={{ color: textColor[index] }}
                  >
                    {item.account}
                  </h3>
                  <div className="d-flex flex-column">
                    <div className="d-flex justify-content-between pt-5 pb-5">
                      <div className="text-gray">{item.title1}</div>
                      <div className="text-white fs-16">{item.title2}</div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center pb-4">
                      <div>
                        <div className="text-gray">{item.title3}</div>
                        <div className="text-gray">{item.title4}</div>
                      </div>
                      <h3 className="text-white fs-32">{item.title5}</h3>
                    </div>
                    <div className="d-flex justify-content-between align-items-center pt-2 pb-2">
                      <div>
                        <div className="text-gray">{item.title6}</div>
                        <div className="text-gray">{item.title7}</div>
                      </div>
                      <h3 className="text-white fs-32">{item.title8}</h3>
                    </div>
                  </div>
                  <div className="d-flex flex-column align-items-center pt-4 pb-2">
                    <Link to={`/${localStorage.getItem("locale")}/login`}>
                      <ButtonAccount background={colors[index]}>
                        {locale.t("button.sub_button.title_1")}
                      </ButtonAccount>
                    </Link>
                    <Link
                      to={`/${localStorage.getItem("locale")}/login`}
                      className="mt-4 text-white"
                    >
                      {locale.t("become_demo")}
                    </Link>
                  </div>
                </Card>
              </div>
            ))} */}
          </div>
        </div>
        <h4 className="text-center pt-3 text-white text-uppercase">
          {locale.t("section_1.title")}
        </h4>
        <p className="text-center pb-3 text-white">
          {locale.t("section_1.description")}
        </p>
        <div
          className="mt-4 overflow-auto overflow-md-hidden d-flex justify-content-md-center"
          style={{ marginBottom: "4rem", overflow: "scroll" }}
        >
          {realaccount?.length > 0 && (
            <table className="table-bordered-iux">
              <thead>
                <tr>
                  <th></th>
                  {realaccount?.map((e, i) =>
                    e.display === "Standard" || e.display === "Raw" ? (
                      <th className="text-gray text-center">
                        {" "}
                        <PopluarCopy>
                          {locale.t("funding.section_2.hot")}
                        </PopluarCopy>
                      </th>
                    ) : (
                      <th className="text-gray text-center">Account</th>
                    )
                  )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={"text-left text-gray "}></td>
                  {realaccount?.map((e, i) => (
                    <td
                      className="font-bold-iux text-center fs-20 "
                      style={{ color: "#FFF" }}
                    >
                      {e.display}
                    </td>
                  ))}
                </tr>

                <tr className="bt-table">
                  <td className={"text-left text-gray p-2 bl-table br-table"}>
                    Trading Platform
                  </td>
                  {realaccount?.map((e, i) => (
                    <td className="text-white text-center px-3 br-table">
                      MetaTrader
                    </td>
                  ))}
                </tr>
                <tr className="bt-table">
                  <td className={"text-left text-gray p-2 bl-table br-table"}>
                    Commission (per lot)
                  </td>
                  {realaccount?.map((e, i) => (
                    <td className="text-white text-center px-3 br-table">
                      ${e.commission}
                    </td>
                  ))}
                </tr>
                <tr className="bt-table">
                  <td className={"text-left text-gray p-2 bl-table br-table"}>
                    Spread from (PIPs)
                  </td>
                  {realaccount?.map((e, i) => (
                    <td className="text-white text-center px-3 br-table">
                      {e.minimum_spread}
                    </td>
                  ))}
                </tr>
                <tr className="bt-table">
                  <td className={"text-left text-gray p-2 bl-table br-table"}>
                    Starting Deposit (USD)
                  </td>
                  {realaccount?.map((e, i) => (
                    <td className="text-white text-center px-3 br-table">
                      ${e.minimum_deposit}
                    </td>
                  ))}
                </tr>
                <tr className="bt-table">
                  <td className={"text-left text-gray p-2 bl-table br-table"}>
                    Leverage
                  </td>
                  {realaccount?.map((e, i) => (
                    <td className="text-white text-center px-3 br-table">
                      1:{e.maximum_leverage}
                    </td>
                  ))}
                </tr>
                <tr className="bt-table">
                  <td className={"text-left text-gray p-2 bl-table br-table"}>
                    Server Location
                  </td>
                  {realaccount?.map((e, i) => (
                    <td className="text-white text-center px-3 br-table">
                      London , Asia pacific
                    </td>
                  ))}
                </tr>
                <tr className="bt-table">
                  <td className={"text-left text-gray p-2 bl-table br-table"}>
                    Micro Lot Trading (0.01)
                  </td>
                  {realaccount?.map((e, i) => (
                    <td className="text-white text-center px-3 br-table">
                      0.01
                    </td>
                  ))}
                </tr>
                <tr className="bt-table">
                  <td className={"text-left text-gray p-2 bl-table br-table"}>
                    Currency Pairs
                  </td>
                  {realaccount?.map((e, i) => (
                    <td className="text-white text-center px-3 br-table">
                      90+
                    </td>
                  ))}
                </tr>
                <tr className="bt-table">
                  <td className={"text-left text-gray p-2 bl-table br-table"}>
                    Index CFDs Trading
                  </td>
                  {realaccount?.map((e, i) => (
                    <td className="text-white text-center px-3 br-table">
                      TRUE
                    </td>
                  ))}
                </tr>
                <tr className="bt-table">
                  <td className={"text-left text-gray p-2 bl-table br-table"}>
                    Stop Out Level
                  </td>
                  {realaccount?.map((e, i) => (
                    <td className="text-white text-center px-3 br-table">
                      {sol[i]}
                    </td>
                  ))}
                </tr>
                <tr className="bt-table">
                  <td className={"text-left text-gray p-2 bl-table br-table"}>
                    Margin Call Level
                  </td>
                  {realaccount?.map((e, i) => (
                    <td className="text-white text-center px-3 br-table">
                      {e?.margin_call_level}%
                    </td>
                  ))}
                </tr>
                <tr className="bt-table">
                  <td className={"text-left text-gray p-2 bl-table br-table"}>
                    One Click Trading
                  </td>
                  {realaccount?.map((e, i) => (
                    <td className="text-white text-center px-3 br-table">
                      MetaTrader
                    </td>
                  ))}
                </tr>
                <tr className="bt-table">
                  <td className={"text-left text-gray p-2 bl-table br-table"}>
                    Islamic Accounts
                  </td>
                  {realaccount?.map((e, i) => (
                    <td className="text-white text-center px-3 br-table">
                      TRUE
                    </td>
                  ))}
                </tr>
                <tr className="bt-table">
                  <td className={"text-left text-gray p-2 bl-table br-table"}>
                    Trading Styles Allowed
                  </td>
                  {realaccount?.map((e, i) => (
                    <td className="text-white text-center px-3 br-table">
                      MetaTrader
                    </td>
                  ))}
                </tr>
                <tr className="bt-table">
                  <td className={"text-left text-gray p-2 bl-table br-table"}>
                    Order Distance Restriction
                  </td>
                  {realaccount?.map((e, i) => (
                    <td className="text-white text-center px-3 br-table">
                      None
                    </td>
                  ))}
                </tr>
                <tr className="bt-table">
                  <td className={"text-left text-gray p-2 bl-table br-table"}>
                    Programming Language
                  </td>
                  {realaccount?.map((e, i) => (
                    <td className="text-white text-center px-3 br-table">
                      MQL5
                    </td>
                  ))}
                </tr>
                <tr className="bt-table bb-table">
                  <td className={"text-left text-gray p-2 bl-table br-table"}>
                    Suitable For
                  </td>
                  {realaccount?.map((e, i) => (
                    <td className="text-white text-center px-3 br-table">
                      Day Traders{" "}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </section>
    </div>
  );
}

export default AccountOverview;
