export const CheckLanguage = (id) => {
  switch (id) {
    case "th":
      return "th_TH";
    case "en":
      return "en";
    case "zh":
      return "zh_CN";
    case "ms":
      return "ms_MY";
    case "hi":
      return "en";
    case "lo":
      return "en";
    case "id":
      return "id";
    case "vi":
      return "vi_VN";
    case "fil":
      return "en";
    case "ja":
      return "ja";
    case "ko":
      return "ko";
    default:
      return "en";
  }
};
//
